import { useState, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next'

export const GuestIntakeCheckbox = ({ required, id, label, additionalDetails, questionType }) => {
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(false)
  const [additionalDetailsRef, setAdditionalDetailsRef] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    if (showAdditionalDetails) {
      setTimeout(() => additionalDetailsRef.focus())
    }
  }, [showAdditionalDetails])

  const handleChange = (state) => {
    if (additionalDetails) {
      setShowAdditionalDetails(state)
    }
  }

  return (
    <>
      {required && (
        <fieldset onChange={(e) => handleChange(e.target.value === 'true')}>
          <div className="flex justify-between items-start">
            <legend className="text-sm font-medium text-gray-700 mb-1">{label}</legend>
            <span className="bg-gray-100 text-gray-400 rounded-md text-xs uppercase p-0.5 px-1.5">
              {t('frontend.form.required')}
            </span>
          </div>
          <div className="sm:text-sm flex space-x-2 text-gray-700 font-medium">
            <label
              htmlFor={`${id}_yes`}
              className="border border-gray-300 shadow-sm rounded-md min-w-[50px] text-center p-2 px-3 space-x-1"
            >
              <input
                value={true}
                type="radio"
                id={`${id}_yes`}
                className="border-gray-300 relative -top-0.5 text-accent focus:outline-none focus:ring focus:ring-accent"
                name={`answers[${id}][customer_response_${questionType}]`}
                required={required}
              />
              <span>{t('frontend.guest_intake.form.yes')}</span>
            </label>
            <label
              htmlFor={`${id}_no`}
              className="border border-gray-300 shadow-sm rounded-md min-w-[50px] text-center p-2 px-3 space-x-1"
            >
              <input
                value={false}
                type="radio"
                id={`${id}_no`}
                className="border-gray-300 relative -top-0.5 text-accent focus:outline-none focus:ring focus:ring-accent"
                name={`answers[${id}][customer_response_${questionType}]`}
                required={required}
              />
              <span>{t('frontend.guest_intake.form.no')}</span>
            </label>
          </div>
        </fieldset>
      )}

      {!required && (
        <label className="flex items-start space-x-2 focus:outline-none focus:ring-accent" for={id}>
          <div className="flex-shrink-0">
            <input
              type="checkbox"
              id={id}
              value={true}
              name={`answers[${id}][customer_response_${questionType}]`}
              className="focus:ring-accent h-4 w-4 text-accent border-gray-300 rounded"
              onChange={(e) => handleChange(e.target.checked)}
            />
          </div>
          <span className="flex-1 font-medium text-gray-700">{label}</span>
        </label>
      )}

      {additionalDetails && (
        <Transition
          show={showAdditionalDetails}
          className="mt-2 ml-2 pl-4 border-l-2 border-gray-300"
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 -transform-y-4"
          enterTo="transform opacity-100 transform-y-0"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 transform-y-0"
          leaveTo="transform opacity-0 -transform-y-4"
          unmount={false}
        >
          <label className="text-sm block font-medium text-gray-700">
            {t('frontend.guest_intake.form.additional_info')}
          </label>
          <input
            type="text"
            name={`answers[${id}][additional_details]`}
            className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm sm:text-sm focus:outline-none focus:ring-1 focus:ring-accent"
            ref={setAdditionalDetailsRef}
          />
        </Transition>
      )}
    </>
  )
}
