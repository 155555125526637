import { Fragment, useState } from 'react'
import { SettingsModal } from './SettingsModal'
import Cookies from 'js-cookie'
import { DateTime } from 'luxon'
import CookieConfig from './CookieConfig.json'
import { Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next'

export const cookieOptions = {
  secure: true,
  expires: DateTime.local().plus({ months: 6 }).toJSDate(),
}

export const CookiePolicy = () => {
  const { t } = useTranslation()
  const [showSettings, setShowSettings] = useState(false)
  const [visible, setVisible] = useState(() => {
    return !Cookies.get('trybe_cookies_consent_complete')
  })

  window.openCookiePrompt = () => {
    setShowSettings(true)
  }

  const handleAcceptAll = () => {
    Object.keys(CookieConfig)
      .filter((key) => !CookieConfig[key].required)
      .forEach((key) => {
        Cookies.set(`trybe_cookies_consent_${key}`, true, cookieOptions)
      })

    if (window.gtag) {
      window.gtag('consent', 'update', {
        analytics_storage: 'granted',
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        ad_storage: 'granted',
      })
    }

    hideConsentPopup()
  }

  const handlePreferencesSaved = (preferences) => {
    Object.keys(preferences)
      .filter((key) => !preferences[key].required)
      .forEach((key) => {
        Cookies.set(`trybe_cookies_consent_${key}`, preferences[key].enabled, cookieOptions)
      })

    if (window.gtag) {
      window.gtag('consent', 'update', {
        analytics_storage: preferences.tracking.enabled ? 'granted' : 'denied',
        ad_user_data: preferences.ads.enabled ? 'granted' : 'denied',
        ad_personalization: preferences.ads.enabled ? 'granted' : 'denied',
        ad_storage: preferences.ads.enabled ? 'granted' : 'denied',
      })
    }

    setShowSettings(false)
    hideConsentPopup()
  }

  const hideConsentPopup = () => {
    // Record that the consent form has been completed.
    Cookies.set('trybe_cookies_consent_complete', true, cookieOptions)

    setVisible(false)
  }

  return (
    <>
      <Transition
        show={visible}
        as={Fragment}
        enter="ease-out duration-300 transition"
        enterFrom="transform opacity-0 translate-y-40"
        enterTo="transform opacity-100 translate-y-0"
        leave="transform ease-in duration-200"
        leaveFrom="transform opacity-100 translate-y-0"
        leaveTo="transform opacity-0 translate-y-40"
      >
        <div
          className="fixed bottom-0 left-0 bg-white border border-gray-200 p-4 rounded-lg shadow-lg m-6 max-w-[420px] text-sm transition-transform duration-500"
          id="cookie-policy"
        >
          <div className="text-gray-600">{t('frontend.legal.cookies.description')}</div>

          <div className="flex mt-3 items-center space-x-5">
            <button
              className="py-2 px-4 bg-accent font-medium rounded-md text-on-accent"
              onClick={handleAcceptAll}
            >
              {t('frontend.legal.cookies.accept_button')}
            </button>

            <button
              className="font-medium text-accent hover:underline"
              onClick={() => setShowSettings(true)}
            >
              {t('frontend.legal.cookies.edit_button')}
            </button>
          </div>
        </div>
      </Transition>

      <SettingsModal
        open={showSettings}
        onClose={() => setShowSettings(false)}
        onSavePreferences={handlePreferencesSaved}
      />
    </>
  )
}
