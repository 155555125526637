import { Modal, ModalCloseButton, ModalInner } from '../Modal'
import { useMemo, useState } from 'react'
import Cookies from 'js-cookie'
import { cookieOptions } from '../CookiePolicy'
import { CheckCircleIcon, ChevronRightIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'

export const LocalePicker = ({ currentLocale, supportedLocales }) => {
  const [open, setOpen] = useState(false)
  const supportedLocalesParsed = useMemo(() => JSON.parse(supportedLocales), [supportedLocales])
  const { t } = useTranslation()

  const setLocale = (localeKey) => {
    Cookies.set('trybe_locale', localeKey, cookieOptions)

    // Now reload the page
    window.location.reload()
  }

  window.openLocalePicker = () => {
    setOpen(true)
  }

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ModalInner>
        <div className="p-6 relative">
          <h1 className="text-lg font-medium text-gray-800">
            {t('frontend.locale_picker.change_language')}
          </h1>

          <ul className="space-y-2 mt-6">
            {Object.keys(supportedLocalesParsed)
              .filter((key) => !supportedLocalesParsed[key].hide)
              .map((localeKey, index) => (
                <li key={index}>
                  <button
                    className="flex items-center justify-between p-3 hover:bg-gray-50 w-full text-left rounded-lg focus:outline-none focus:ring focus:ring-accent"
                    onClick={() => setLocale(localeKey)}
                    aria-selected={currentLocale === localeKey}
                  >
                    <div className="flex space-x-2 items-center">
                      <span>{supportedLocalesParsed[localeKey].emoji}</span>
                      <span>{supportedLocalesParsed[localeKey].name}</span>
                    </div>
                    <div className="flex-shrink-0 text-gray-500">
                      {currentLocale === localeKey && (
                        <CheckCircleIcon className="text-accent w-6 h-6" aria-hidden="true" />
                      )}

                      {currentLocale !== localeKey && (
                        <ChevronRightIcon className="w-6 h-6" aria-hidden="true" />
                      )}
                    </div>
                  </button>
                </li>
              ))}
          </ul>
          <ModalCloseButton onClick={() => setOpen(false)} />
        </div>
      </ModalInner>
    </Modal>
  )
}
