import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu'
import 'react-horizontal-scrolling-menu/dist/styles.css'
import { Money } from './Money'
import { AddRetailProductButton } from './AvailabilityCheck/AddRetailProductButton'
import { useContext } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext)

  return (
    <button
      className="hidden md:block aspect-square right-[40px] -top-12 disabled:cursor-not-allowed disabled:opacity-50 rounded-md shadow-sm z-10 bg-white absolute border border-gray-300 p-1"
      disabled={isFirstItemVisible}
      onClick={() => scrollPrev()}
    >
      <ChevronLeftIcon className="w-6 h-6" />
    </button>
  )
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = useContext(VisibilityContext)

  return (
    <button
      className="hidden md:block aspect-square right-0 -top-12 disabled:cursor-not-allowed disabled:opacity-50 rounded-md shadow-sm z-10 bg-white absolute border border-gray-300 p-1"
      disabled={isLastItemVisible}
      onClick={() => scrollNext()}
    >
      <ChevronRightIcon className="w-6 h-6" />
    </button>
  )
}

export const RetailCrossSell = ({ guests, items }) => (
  <ScrollMenu
    LeftArrow={LeftArrow}
    RightArrow={RightArrow}
    scrollContainerClassName="space-x-4 scrollbar-hide"
    wrapperClassName={`
      after:w-px after:-right-px after:absolute after:inset-y-0 after:z-20 after:shadow-[-1px_0_10px_10px_rgb(255,255,255)]
    `}
  >
    {JSON.parse(items).map((item, index) => (
      <div key={index} className="flex-shrink-0 w-[175px] space-y-2">
        <div className="bg-gray-300 rounded-md overflow-hidden aspect-offering flex items-center relative">
          {item.image && (
            <img
              src={item.image.url}
              alt={item.image.alt}
              className="w-full h-full aspect-offering object-center object-cover"
            />
          )}
        </div>
        <div>
          <div className="font-semibold truncate">{item.name}</div>
          <div className="text-sm text-gray-500">
            <Money amount={item.price} currency={item.currency} />
          </div>
          <AddRetailProductButton
            guests={guests}
            id={item.id}
            offeringImage={item.image?.url}
            offeringName={item.name}
          />
        </div>
      </div>
    ))}
  </ScrollMenu>
)
