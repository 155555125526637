import { ExclamationIcon } from '@heroicons/react/solid'
import { useEffect, useState } from 'react'
import { useOffering } from '../AvailabilityCheck/Context'
import {
  useDeletePackageItem,
  useDeletePackageItemOption,
  useUpdatePackageItem,
  useUpdateVisitPackageItem,
  useUpdateItem,
} from '../OrderHooks'
import { ChoiceV2 } from './ChoiceV2'
import { useTranslation } from 'react-i18next'

export const ConfigureForGuestV2 = ({
  basketItemId,
  basketId = null, // When null, we'll use the session basket.
  isVisit = false,
  choices,
  onComplete,
  onEdit,
  onAdd,
  onShowTimePicker,
  initialCurrentOption = 0,
  initialSelectedOptions = [],
  initialMinimiseAll = false,
  initialBudgets = [],
  hidePrices = false,
}) => {
  const [budgets, setBudgets] = useState(initialBudgets)
  const [currentOption, setCurrentOption] = useState(initialCurrentOption)
  const [selectedOptions, setSelectedOptions] = useState(initialSelectedOptions)
  const [minimiseAll, setMinimiseAll] = useState(initialMinimiseAll)
  const { t } = useTranslation()
  const { setBasket, setBasketItemId } = useOffering()

  const {
    mutateAsync: updatePackageItem,
    isError: isUpdateError,
    error: updateError,
  } = isVisit ? useUpdateVisitPackageItem(basketId) : useUpdatePackageItem(basketId)

  const {
    mutateAsync: updateItem,
    isError: isUpdateItemError,
    error: updateItemError,
  } = useUpdateItem()

  const {
    mutateAsync: deletePackageItem,
    isError: isDeleteError,
    error: deleteError,
  } = useDeletePackageItem(basketId)

  const { mutateAsync: deletePackageItemOption } = useDeletePackageItemOption(basketId)

  const isError = isUpdateError || isUpdateItemError || isDeleteError
  const error = updateError ?? updateItemError ?? deleteError

  useEffect(() => {
    return () => {
      setCurrentOption(0)
    }
  }, [])

  useEffect(() => {
    if (basketItemId) {
      setBasketItemId(basketItemId)
    }
  }, [basketItemId])

  const handleEdit = async (choiceIndex, choiceId) => {
    if (onEdit) {
      onEdit(choiceIndex)
    }

    const hasSharedBasketItem = itemsForChoice.some((item) => !!item.shared_basket_item_id)
    const nextChoice = choices[choiceIndex + 1]
    let updatedBasket, updatedItem

    // If this choice has a shared basket item chosen, we don't want to
    // delete this choice but do want to remove any choices after it
    if (hasSharedBasketItem && nextChoice !== undefined) {
      updatedBasket = await deletePackageItem({ basketItemId, choiceId: nextChoice.id })
      updatedItem = (updatedBasket.items ?? []).find((item) => item.id === basketItemId)
    } else if (!hasSharedBasketItem) {
      updatedBasket = await deletePackageItem({ basketItemId, choiceId })
      updatedItem = (updatedBasket.items ?? []).find((item) => item.id === basketItemId)
    }

    // Update our state if we got an updated item
    if (updatedItem !== undefined) {
      setBudgets(updatedItem.option_budgets)
      setSelectedOptions(updatedItem.package_items)
    }

    setMinimiseAll(false)
    setCurrentOption(choiceIndex)
  }

  const handleAdd = async (vals, choiceIndex, optionIndex) => {
    if (onAdd) {
      onAdd(choiceIndex, optionIndex)
    }

    if (vals) {
      // We're slowly migrating to having basket item config in item_configuration,
      // so for now we copy the values to there
      vals.item_configuration = { ...vals }

      const newBasket = await updatePackageItem({ ...vals, basketItemId })
      const item = (newBasket.items ?? []).find((item) => item.id === basketItemId)

      setBasket(newBasket)
      setBudgets(item.option_budgets)
      setSelectedOptions(item.package_items)
    }
  }

  const handleUpdateBasketItem = async (basketItemId, vals) => {
    await updateItem({ basketItemId, values: { ...vals, basket_id: basketId } })
  }

  const handleRemove = async (itemId) => {
    const newBasket = await deletePackageItemOption({
      basketItemId,
      itemId,
    })

    const item = (newBasket.items ?? []).find((item) => item.id === basketItemId)

    setBasket(newBasket)
    setBudgets(item.option_budgets)
    setSelectedOptions(item.package_items)
  }

  const handleProgress = () => {
    if (currentOption === choices.length - 1) {
      onComplete()
    } else {
      setTimeout(() => {
        setCurrentOption((prev) => prev + 1)
      })
    }
  }

  const handleShowTimePicker = (choiceIndex, optionIndex) => {
    if (onShowTimePicker) {
      onShowTimePicker(choiceIndex, optionIndex)
    }
  }

  const choice = choices[currentOption]
  const index = currentOption
  const itemsForChoice = (selectedOptions ?? []).filter((option) => option.choice_id === choice.id)

  return (
    <>
      {isError && (
        <div className="mx-4 mb-4 p-3 rounded-md bg-red-100 text-red-700 flex space-x-1">
          <ExclamationIcon className="flex-shrink-0 relative top-1 w-5 h-5" aria-hidden="true" />
          <span>
            {error?.message ?? t('frontend.configure_package.configure_for_guest.error_message')}
          </span>
        </div>
      )}

      {index > 0 && (
        <button
          className="hover:underline text-xs text-gray-500 px-4 mb-2"
          onClick={() => handleEdit(index - 1, choices[index - 1].id)}
        >
          {t('frontend.check_availability.package_config.back_to_step', { step: index })}
        </button>
      )}

      <div className="space-y-6">
        <ChoiceV2
          hidePrices={hidePrices}
          budget={budgets.find((budget) => budget.choice_id === choice.id)}
          itemsForChoice={itemsForChoice}
          onAdd={(vals, optionIndex) => handleAdd(vals, index, optionIndex)}
          onUpdateBasketItem={handleUpdateBasketItem}
          onRemove={(vals) => handleRemove(vals)}
          onShowTimePicker={(optionIndex) => handleShowTimePicker(index, optionIndex)}
          onContinue={handleProgress}
          key={`choice_${index}`}
          index={index + 1}
          choice={choice}
          totalChoices={choices.length}
          canEdit={currentOption > index || minimiseAll}
          onEdit={() => handleEdit(index, choice.id)}
          expanded={currentOption === index && !minimiseAll}
          autoSelectTimeslot={choice.auto_select_timeslot}
          requiresSlots={['session', 'area_booking', 'appointment', 'table_reservation'].includes(
            choice.offering_type
          )}
        />
      </div>
    </>
  )
}
