import { useMemo, useState } from 'react'
import { Modal, ModalInner, ModalCloseButton } from './Modal'

export const ModalLink = ({ text, contents }) => {
  const [isOpen, setIsOpen] = useState(false)
  const parsedContents = useMemo(() => {
    try {
      return JSON.parse(contents)
    } catch (e) {
      return ''
    }
  }, [contents])

  return (
    <>
      <button
        type="button"
        className="font-medium text-accent hover:underline"
        onClick={() => setIsOpen(true)}
        title={text}
      >
        {text}
      </button>

      <Modal open={isOpen} onClose={() => setIsOpen(false)} size="sm:max-w-lg">
        <ModalInner>
          <div className="relative">
            <h1 className="text-lg p-6 font-medium text-gray-800">{text}</h1>

            <div
              dangerouslySetInnerHTML={{ __html: parsedContents }}
              className="prose prose-sm overflow-y-scroll max-h-[75vh] px-6 pb-6"
            />

            <ModalCloseButton onClick={() => setIsOpen(false)} />
          </div>
        </ModalInner>
      </Modal>
    </>
  )
}
