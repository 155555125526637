import { ExclamationIcon } from '@heroicons/react/solid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOffering } from '../AvailabilityCheck/Context'
import {
  useUpdatePackageItem,
  useDeletePackageItem,
  useDeletePackageItemOption,
} from '../OrderHooks'
import { Choice } from './Choice'

export const ConfigureForGuest = ({
  basketItemId,
  basketId = null, // When null, we'll use the session basket.
  choices,
  onComplete,
  onEdit,
  onAdd,
  onShowTimePicker,
  initialCurrentOption = 0,
  initialSelectedOptions = [],
  initialMinimiseAll = false,
  initialBudgets = [],
  hidePrices = false,
}) => {
  const [budgets, setBudgets] = useState(initialBudgets)
  const [currentOption, setCurrentOption] = useState(initialCurrentOption)
  const [selectedOptions, setSelectedOptions] = useState(initialSelectedOptions)
  const [minimiseAll, setMinimiseAll] = useState(initialMinimiseAll)
  const { setBasketItemId } = useOffering()
  const { t } = useTranslation()

  const {
    mutateAsync: updatePackageItem,
    isError: isUpdateError,
    error: updateError,
  } = useUpdatePackageItem(basketId)

  const {
    mutateAsync: deletePackageItem,
    isError: isDeleteError,
    error: deleteError,
  } = useDeletePackageItem(basketId)

  const { mutateAsync: deletePackageItemOption } = useDeletePackageItemOption(basketId)

  const isError = isUpdateError || isDeleteError
  const error = updateError ?? deleteError

  useEffect(() => {
    return () => {
      setCurrentOption(0)
    }
  }, [])

  useEffect(() => {
    if (basketItemId) {
      setBasketItemId(basketItemId)
    }
  }, [basketItemId])

  const handleEdit = async (choiceIndex, choiceId) => {
    if (onEdit) {
      onEdit(choiceIndex)
    }

    const newBasket = await deletePackageItem({ basketItemId, choiceId })
    const item = (newBasket.items ?? []).find((item) => item.id === basketItemId)

    // The API has removed all items _after_ this one, so update our state.
    setBudgets(item.option_budgets)
    setSelectedOptions(item.package_items)

    setMinimiseAll(false)
    setCurrentOption(choiceIndex)
  }

  const handleAdd = async (vals, choiceIndex, optionIndex) => {
    if (onAdd) {
      onAdd(choiceIndex, optionIndex)
    }

    if (vals) {
      const newBasket = await updatePackageItem({ ...vals, basketItemId })
      const item = (newBasket.items ?? []).find((item) => item.id === basketItemId)

      setBudgets(item.option_budgets)
      setSelectedOptions(item.package_items)
    }
  }

  const handleRemove = async (itemId) => {
    const newBasket = await deletePackageItemOption({
      basketItemId,
      itemId,
    })

    const item = (newBasket.items ?? []).find((item) => item.id === basketItemId)

    setBudgets(item.option_budgets)
    setSelectedOptions(item.package_items)
  }

  const handleProgress = () => {
    if (currentOption === choices.length - 1) {
      onComplete()
    } else {
      setTimeout(() => {
        setCurrentOption((prev) => prev + 1)
      })
    }
  }

  const handleShowTimePicker = (choiceIndex, optionIndex) => {
    if (onShowTimePicker) {
      onShowTimePicker(choiceIndex, optionIndex)
    }
  }

  return (
    <>
      {isError && (
        <div className="mx-6 my-4 p-3 mb-0 rounded-md bg-red-100 text-red-700 flex space-x-1">
          <ExclamationIcon className="relative top-1 w-5 h-5" aria-hidden="true" />
          <span>
            {error?.message ?? t('frontend.configure_package.configure_for_guest.error_message')}
          </span>
        </div>
      )}

      <div className="space-y-6">
        {choices.map((choice, index) => (
          <Choice
            hidePrices={hidePrices}
            budget={budgets.find((budget) => budget.choice_id === choice.id)}
            selectedOption={(selectedOptions ?? []).filter(
              (option) => option.choice_id === choice.id
            )}
            onAdd={(vals, optionIndex) => handleAdd(vals, index, optionIndex)}
            onRemove={(vals) => handleRemove(vals)}
            onShowTimePicker={(optionIndex) => handleShowTimePicker(index, optionIndex)}
            onContinue={handleProgress}
            key={`choice_${index}`}
            index={index + 1}
            choice={choice}
            canEdit={currentOption > index || minimiseAll}
            onEdit={() => handleEdit(index, choice.id)}
            expanded={currentOption === index && !minimiseAll}
            requiresSlots={
              ['session', 'area_booking', 'appointment'].includes(choice.offering_type) &&
              !choice.auto_select_timeslot
            }
          />
        ))}
      </div>
    </>
  )
}
