import { CheckIcon, ClockIcon } from '@heroicons/react/solid'
import { useEffect, useMemo, useState } from 'react'
import { DurationFilters, TimeSlotPicker } from '../AvailabilityCheck/TimeSlotPicker'
import { formatMoney } from '../Money'
import { DateTime } from 'luxon'
import { AnimatePresence, motion } from 'framer-motion'
import { useOffering } from '../AvailabilityCheck/Context'
import { useTranslation } from 'react-i18next'
import { useFormatFromNumberOfMinutes } from '../Duration'

const Duration = ({ mins }) => {
  const formatFromNumberOfMinutes = useFormatFromNumberOfMinutes(mins)

  return <div className="mt-1 text-xs font-normal text-gray-500">{formatFromNumberOfMinutes}</div>
}

const OptionPriceChange = ({ amount, currency }) => (
  <>
    {' '}
    <span className="text-gray-500">+{formatMoney({ amount, currency })}</span>
  </>
)

const OptionPrice = ({ from, to, currency }) => {
  const { t } = useTranslation()

  return (
    <>
      {' '}
      <span className="text-gray-500">
        {from === to && formatMoney({ amount: from, currency })}
        {from !== to &&
          t('frontend.check_availability.package_config.from_price', {
            price: formatMoney({ amount: from, currency }),
          })}
      </span>
    </>
  )
}

const UnavailableChoiceOption = ({ name }) => {
  const { t } = useTranslation()

  return (
    <button
      className="opacity-50 bg-white p-2 px-4 flex items-start space-x-3 cursor-not-allowed w-full text-left"
      disabled
    >
      <div
        className={`w-5 h-5 relative top-1 border border-gray-300 shadow-sm bg-gray-100 rounded-md`}
      />
      <div>
        <div className="font-medium text-gray-800">{name}</div>
        <div className="text-sm text-gray-500">
          {t('frontend.check_availability.package_config.no_availability')}
        </div>
      </div>
    </button>
  )
}

const LoadingChoiceOption = () => (
  <button
    className="animate-pulse flex space-x-3 items-start w-full p-2 px-4 text-left disabled"
    disabled
  >
    <div className="w-5 h-5 relative top-1 rounded-full bg-gray-200" />
    <div>
      <div className="w-24 h-5 bg-gray-200" />
      <div className="mt-1 h-8 bg-gray-200" />
    </div>
  </button>
)

export const ChoiceOption = ({
  option,
  onRequireSlot,
  shouldShowTimePicker,
  onAdd,
  onRemove,
  onHideTimePicker,
  requiresSlots,
  getSlotsHook,
  showFromPrice = false,
  applied = false,
  shouldSkipInitialAvailabilityCheck,
  shouldSelectOnMount = false,
  shouldShowPriceChange = false,
}) => {
  const { t } = useTranslation()
  const [isMutating, setMutating] = useState(false)
  const { date } = useOffering()
  const [selectedDuration, setSelectedDuration] = useState(() => {
    if (option?.offering?.durations) {
      return option.offering.durations[0]
    }

    return null
  })

  const { isLoading, refetch, data: { data: packageSlots = [] } = {} } = getSlotsHook()

  const slots = useMemo(() => {
    let slotsForOption = packageSlots.find((s) => s.id === option.id)?.slots ?? []

    if (selectedDuration) {
      slotsForOption = slotsForOption.filter((s) => s.duration === selectedDuration)
    }

    return slotsForOption
  }, [packageSlots, selectedDuration])

  // If this is true, we'll auto-select the option as the component
  // is mounted. This is used then there's only one ChoiceOption present.
  useEffect(() => {
    if (shouldSelectOnMount) {
      handleSelect()
    }
  }, [shouldSelectOnMount])

  useEffect(() => {
    return () => {
      setMutating(false)
    }
  }, [])

  const handleSelect = async () => {
    if (requiresSlots && shouldShowTimePicker) {
      onHideTimePicker()

      return
    }

    if (requiresSlots && !shouldShowTimePicker) {
      onRequireSlot()
      refetch()

      return
    }

    if (isMutating || isLoading) {
      // It's loading, don't allow a click
      return
    }

    const vals = {}

    // If an appointment type gets here, it means this choice
    // is set up to auto-select a timeslot. We still need to
    // pass a time to the API, so use midnight of the selected date.
    if (option.item_type === 'appointment') {
      vals.time = date.toISO({ suppressMilliseconds: true })
    }

    setMutating(true)

    if (applied) {
      await onRemove()
    } else {
      await onAdd(vals)
    }

    setMutating(false)
  }

  const handlePickTimeslot = async (timeslot) => {
    setMutating(true)

    // If we already have this offering in the order, remove it
    // before adding it again at the newly selected time.
    // Fixes SC-15682
    if (applied) {
      await onRemove()
    }

    await onAdd({
      time: DateTime.fromJSDate(timeslot.start_time).toISO({ suppressMilliseconds: true }),
      duration: timeslot.duration,
    })

    setMutating(false)
  }

  if (isLoading) {
    return <LoadingChoiceOption name={option.offering.name} />
  }

  if (
    !shouldSkipInitialAvailabilityCheck &&
    requiresSlots &&
    (slots.length === 0 || slots.every((s) => s.quantity_available === 0))
  ) {
    return <UnavailableChoiceOption name={option.offering.name} />
  }

  return (
    <div
      className={`${shouldShowTimePicker ? 'bg-gray-50' : 'bg-white hover:bg-gray-50'} rounded-lg`}
      data-trybe-element="package-choice-option"
    >
      <button
        className={`
          group p-2 px-4 w-full items-center text-left cursor-pointer
          focus:outline-none
          active:outline-none
        `}
        onClick={handleSelect}
      >
        <div className="flex space-x-3">
          <div className="flex-shrink-0 relative">
            <div
              className={`
                w-5 h-5 relative top-1 border border-gray-300
                shadow-sm bg-white rounded-md
                group-focus:ring-2 group-focus:ring-offset-1 group-focus:ring-accent
                group-active:ring-2 group-active:ring-offset-1 group-active:ring-accent
              `}
            >
              {isMutating && (
                <div className="bg-accent w-3 h-3 m-[3px] animate-pulse rounded absolute" />
              )}

              {!isMutating && applied && (
                <div className="bg-accent w-3 h-3 m-[3px] rounded absolute">
                  <CheckIcon className="w-3 h-3 text-white" />
                </div>
              )}
            </div>
          </div>
          <div className="flex-1 m-[1.5px]">
            <div className="flex justify-between font-medium text-gray-800">
              <span className="break-normal">{option.offering.name}</span>
              {!showFromPrice && shouldShowPriceChange && option.price_change > 0 && (
                <OptionPriceChange
                  amount={option.price_change}
                  currency={option.offering.currency}
                />
              )}
              {showFromPrice && (
                <OptionPrice
                  from={option.offering.price_from}
                  to={option.offering.price_to}
                  currency={option.offering.currency}
                />
              )}
            </div>

            {option.offering.description && (
              <div
                className="text-gray-500 text-sm line-clamp-2"
                dangerouslySetInnerHTML={{ __html: option.offering.description }}
              />
            )}

            {option.offering.duration && <Duration mins={option.offering.duration} />}
          </div>
        </div>
      </button>
      <AnimatePresence>
        {requiresSlots && shouldShowTimePicker && (
          <motion.div
            style={{ overflow: 'hidden' }}
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div
              className="flex justify-between items-center px-3"
              data-trybe-element="package-choice-option-time-picker-header"
            >
              <div className="flex space-x-3 font-medium text-sm">
                <ClockIcon className="w-5 h-5 ml-1" />
                <span>{t('frontend.check_availability.package_config.now_pick_a_time')}</span>
              </div>
              {option.offering?.durations && option.offering.durations.length > 1 && (
                <div className="text-xs">
                  <DurationFilters
                    durations={option.offering.durations}
                    selectedDuration={selectedDuration}
                    onDurationSelect={setSelectedDuration}
                  />
                </div>
              )}
            </div>
            <div className="p-3" data-trybe-element="package-choice-option-time-picker">
              <TimeSlotPicker slots={slots} isLoading={isLoading} onSelect={handlePickTimeslot} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
