import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const Spinner = ({ variant, height = 'h-5', width = 'w-5', margin = 'my-2' }) => {
  const textColor = useMemo(() => {
    switch (variant) {
      default:
        return ''
      case 'info':
        return 'text-blue-400'
      case 'success':
        return 'text-green-400'
      case 'warning':
        return 'text-yellow-400'
      case 'danger':
        return 'text-red-400'
    }
  }, [variant])

  const { t } = useTranslation()

  return (
    <span className={textColor}>
      <span className="sr-only">{t('frontend.spinner.loading')}</span>
      <svg
        className={`animate-spinner-path stroke-current ${height} ${width} ${margin} mx-auto`}
        viewBox="0 0 50 50"
      >
        <circle
          strokeLinecap="round"
          className="animate-spinner-path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="5"
        ></circle>
      </svg>
    </span>
  )
}
