import { DateTime } from 'luxon'
import { TimeSelectionStage } from './AvailabilityCheck/CheckAvailabilityButton'
import { OfferingProvider } from './AvailabilityCheck/Context'
import { ConfigureForGuest } from './ConfigurePackage/ConfigureForGuest'
import { OfferingModal } from './OfferingModal'
import { useUpdateItem, usePackage } from './OrderHooks'
import { useTranslation } from 'react-i18next'
import { ExclamationIcon } from '@heroicons/react/solid'
import { ConfigureForGuestV2 } from './ConfigurePackage/ConfigureForGuestV2'

export const EditItem = ({ item, onClose, siteId, onEditFinished }) => {
  const { isLoading: isUpdating, isError, error, mutate: updateItem } = useUpdateItem()

  const { t } = useTranslation()

  const { data: { data: packageData = {} } = {} } = usePackage(item?.offering_id, siteId, {
    enabled: item?.offering_type === 'package',
  })

  const handleUpdate = (arrivalTime, duration) => {
    const payload = {
      selectedDate: DateTime.fromISO(item.date),
      duration: duration,
      time: DateTime.fromJSDate(arrivalTime).toISO({ suppressMilliseconds: true }),
    }

    updateItem(
      {
        basketItemId: item.id,
        values: payload,
      },
      {
        onSuccess: () => {
          onEditFinished()
          onClose()
        },
      }
    )
  }

  const handleUpdatePackage = async () => {
    // The ConfigureForGuest already handles updating the basket,
    // so we can just close the modal here.
    onEditFinished()
    onClose()
  }

  return (
    <OfferingProvider
      id={item?.offering_id}
      basketItemId={item?.id}
      offeringType={item?.offering_type}
      offeringName={item?.offering_name}
      date={item?.date ? DateTime.fromISO(item.date) : null}
      offeringImage={item?.image?.url}
      siteId={siteId}
    >
      <OfferingModal isOpen={item !== null} onClose={onClose}>
        {item !== null && item?.offering_type === 'package' && packageData?.item_choices && (
          <div className="p-6 px-3 md:px-4">
            {window.featureFlags.includes('package_config_tweaks') && (
              <ConfigureForGuestV2
                basketItemId={item.id}
                choices={packageData?.item_choices ?? []}
                onComplete={handleUpdatePackage}
                initialCurrentOption={packageData?.item_choices?.length - 1 ?? 0}
                initialSelectedOptions={item.package_items}
                initialMinimiseAll
                initialBudgets={item.option_budgets}
              />
            )}

            {!window.featureFlags.includes('package_config_tweaks') && (
              <ConfigureForGuest
                basketItemId={item.id}
                choices={packageData?.item_choices ?? []}
                onComplete={handleUpdatePackage}
                initialCurrentOption={packageData?.item_choices?.length - 1 ?? 0}
                initialSelectedOptions={item.package_items}
                initialMinimiseAll
                initialBudgets={item.option_budgets}
              />
            )}
          </div>
        )}

        {item !== null && item?.offering_type !== 'package' && (
          <>
            {isError && (
              <div className="mx-6 my-4 p-3 mb-0 rounded-md bg-red-100 text-red-700 flex space-x-1">
                <ExclamationIcon className="relative top-1 w-5 h-5" />
                <span>{error?.message ?? t('frontend.check_availability.an_error_occurred')}</span>
              </div>
            )}

            <TimeSelectionStage
              onSelect={handleUpdate}
              isAdding={isUpdating}
              currentGuest={item.guest}
            />
          </>
        )}
      </OfferingModal>
    </OfferingProvider>
  )
}
