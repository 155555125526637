import { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { DateTime, SystemZone, Settings as DateTimeSettings } from 'luxon'
import { useAvailableDates } from '../OrderHooks'
import { locale } from '../../../app'
import { useTranslation } from 'react-i18next'

const LoadingDate = () => <div className="w-8 h-8 mb-1 p-5 bg-gray-200 animate-pulse rounded-md" />

const ErrorBlock = () => {
  const { t } = useTranslation()

  return (
    <div className="h-64 bg-gray-100 flex justify-center items-center rounded-md">
      <div className="font-medium text-gray-600">
        {t('frontend.check_availability.an_error_occurred')}
      </div>
    </div>
  )
}

export const DatePicker = ({ onChange, value }) => {
  const [selectedDate, setSelectedDate] = useState(null)

  // Outside of the datepicker we want the date to be in the site's timezone,
  // but we want to pass it a date that's midnight in the system's zone.
  useEffect(() => {
    if (value) {
      setSelectedDate(value.setZone(new SystemZone(), { keepLocalTime: true }))
    }
  }, [value])

  const [dateFrom, setDateFrom] = useState(() => {
    // If the selected date is in the same month as today, use today
    if (!value || value?.month === DateTime.now().month) {
      return DateTime.now()
    }
    // Otherwise use the start of the month of the selected date
    return value.startOf('month')
  })

  const { isLoading, isError, data } = useAvailableDates(dateFrom)

  const filterDate = (date) => {
    if (isLoading) return false

    const luxonDate = dateInShopTimezone(date)
    const isoDate = luxonDate.toISODate()
    const found = data.data.find((dateAvailability) => {
      // - Y-m-d dates from the SDK are instantiated as Date objects, and JS assumes UTC for these.
      // - Dates from the date picker are Date objects in midnight in the system timezone
      // So we convert the SDK dates to midnight in the system timezone too before comparing
      const availabilityDate = DateTime.fromJSDate(dateAvailability.date)
        .setZone('UTC')
        .setZone(new SystemZone(), { keepLocalTime: true })

      return availabilityDate.toISODate() === isoDate
    })

    return found && (found.has_availability !== false || found.can_enquire)
  }

  const getClassForDate = (date) => {
    if (isLoading) return ''

    const luxonDate = dateInShopTimezone(date)
    const isoDate = luxonDate.toISODate()
    const found = data.data.find(
      (availabilityDate) => DateTime.fromJSDate(availabilityDate.date).toISODate() === isoDate
    )

    return found && found.has_availability === false && found.can_enquire ? 'opacity-60' : ''
  }

  const renderDayContents = (day, date) => {
    if (isLoading) return <LoadingDate />

    return day
  }

  const handleChange = (jsDate) => {
    const date = dateInShopTimezone(jsDate)

    onChange(date)

    window.gtag('event', 'date_picker_change', {
      event_category: 'engagement',
      event_label: date.toISODate(),
    })
  }

  const handleMonthChange = (date) => {
    setDateFrom(date)

    window.gtag('event', 'date_picker_month_change', {
      event_category: 'engagement',
      event_label: date.toISODate(),
    })
  }

  if (isError) {
    return <ErrorBlock />
  }

  return (
    <ReactDatePicker
      inline
      filterDate={filterDate}
      onMonthChange={(date) => handleMonthChange(DateTime.fromJSDate(date).startOf('month'))}
      renderDayContents={renderDayContents}
      dayClassName={getClassForDate}
      onChange={handleChange}
      selected={selectedDate?.toJSDate() ?? null}
      minDate={DateTime.now().setZone(new SystemZone(), { keepLocalTime: true }).toJSDate()}
      calendarStartDay={1}
      peekNextMonth={false}
      locale={locale}
    />
  )
}

// The date picker gives us a JS date in the browser's local time zone. We want it in
// the site's time zone, so we first need to create a DateTime object keeping the
// browser (system) time zone then we convert it to the default (site) zone keeping the
// time as it was, so midnight in Paris time is still midnight but now in London time.
const dateInShopTimezone = (date) => {
  return DateTime.fromJSDate(date, {
    zone: new SystemZone(),
  }).setZone(DateTimeSettings.defaultZone, {
    keepLocalTime: true,
  })
}
