import { Modal, ModalInner, ModalCloseButton } from '../Modal'
import { Switch } from '@headlessui/react'
import { useState } from 'react'
import CookieConfig from './CookieConfig.json'
import { useTranslation } from 'react-i18next'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const SettingsModal = ({ open, onClose, onSavePreferences }) => {
  const [cookies, setCookies] = useState(CookieConfig)
  const { t } = useTranslation()

  const handleChange = (state, cookie) => {
    const updatedCookies = { ...cookies }

    updatedCookies[cookie].enabled = state

    setCookies(updatedCookies)
  }

  return (
    <Modal open={open} onClose={onClose}>
      <ModalInner>
        <div className="p-6 relative">
          <h1 className="text-lg font-medium text-gray-800">
            {t('frontend.legal.cookies.edit_button')}
          </h1>

          <p className="text-sm text-gray-500 my-3">
            {t('frontend.legal.cookies.manage_your_cookies')}
          </p>

          <ul className="divide-y divide-gray-100">
            {Object.keys(cookies).map((cookie) => (
              <CookieRow
                key={cookie}
                title={t(cookies[cookie].title_key)}
                description={t(cookies[cookie].description_key)}
                disabled={cookies[cookie].required ?? false}
                enabled={cookies[cookie].enabled}
                onChange={(state) => handleChange(state, cookie)}
              />
            ))}
          </ul>

          <button
            onClick={() => onSavePreferences(cookies)}
            type="button"
            className="bg-accent text-on-accent font-medium p-3 rounded-md w-full mt-3"
          >
            {t('frontend.legal.cookies.save_and_close')}
          </button>

          <ModalCloseButton onClick={onClose} />
        </div>
      </ModalInner>
    </Modal>
  )
}

const CookieRow = ({ title, description, onChange, enabled, disabled }) => {
  const { t } = useTranslation()

  return (
    <li className="flex w-full py-3 items-start justify-between space-x-6">
      <div className="flex-1 text-sm">
        <div className="text-gray-800 font-medium">{title}</div>
        <div className="text-gray-500">{description}</div>
      </div>
      <div className="">
        <Switch
          disabled={disabled}
          checked={enabled}
          onChange={onChange}
          className={classNames(
            enabled ? 'bg-accent' : 'bg-gray-200',
            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent disabled:opacity-50 disabled:cursor-not-allowed'
          )}
        >
          {enabled && (
            <span className="sr-only">
              {t('frontend.legal.cookies.disable_cookie', {
                cookie: title,
              })}
            </span>
          )}

          {!enabled && (
            <span className="sr-only">
              {t('frontend.legal.cookies.enable_cookie', {
                cookie: title,
              })}
            </span>
          )}
          <span
            aria-hidden="true"
            className={classNames(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
            )}
          />
        </Switch>
      </div>
    </li>
  )
}
