import React, { useMemo, useState } from 'react'
import { useOffering } from '../AvailabilityCheck/Context'
import { ConfigureModal } from './ConfigureModal'
import { useTranslation } from 'react-i18next'

export const ConfigurePackageButton = ({
  requireMemberGuests = false,
  maxGuests,
  separateNameValuesEnforced = false,
}) => {
  const [showModal, setShowModal] = useState(false)
  const { date, offeringName } = useOffering()
  const canSubmit = useMemo(() => date !== null, [date])
  const { t } = useTranslation()

  const handleClose = () => {
    setShowModal(false)

    window.gtag('event', 'pre_add_to_cart_cancel', {
      event_category: 'engagement',
      event_label: offeringName,
    })
  }

  const handleDone = async () => {
    setShowModal(false)
    window.openSlideover()
  }

  const handleClick = () => {
    setShowModal(true)

    window.gtag('event', 'pre_add_to_cart_open', {
      event_category: 'engagement',
      event_label: offeringName,
    })
  }

  return (
    <>
      <button
        type="button"
        onClick={handleClick}
        className={`${
          canSubmit ? 'hover:bg-accent/90' : 'cursor-not-allowed opacity-50'
        } relative overflow-hidden w-full mt-4 items-center px-4 py-2 border border-transparent font-medium rounded-md shadow-sm text-on-accent bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent`}
        disabled={!canSubmit}
      >
        {t('frontend.check_availability.package_config.configure_package')}
      </button>

      <div className="text-xs text-center text-gray-500 mt-2">
        {t('frontend.check_availability.package_config.configure_package_helper_text')}
      </div>

      <ConfigureModal
        isOpen={showModal}
        onClose={handleClose}
        onDone={() => handleDone()}
        requireMemberGuests={requireMemberGuests}
        maxGuests={maxGuests}
        separateNameValuesEnforced={separateNameValuesEnforced}
      />
    </>
  )
}
