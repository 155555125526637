import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMembership } from './index'

export const Summary = () => {
  const { isLoading, totals } = useMembership()

  const [summaryLines, setSummaryLines] = useState([])

  const { t } = useTranslation()

  useEffect(() => {
    if (isLoading) return

    // For each summary line, wrap strings wrapped with ** in a <strong> tag.
    setSummaryLines(
      (totals?.summary_lines ?? []).map((line) =>
        line.replace(/\*\*(.*?)\*\*/g, '<span class="font-medium text-accent">$1</span>')
      )
    )
  }, [totals.summary_lines, isLoading])

  return (
    <>
      <div>
        <div className="text-lg font-medium mt-8">
          {t('frontend.self_signup.summary_breakdown.header')}
        </div>
        <p className="text-sm text-gray-500">
          {t('frontend.self_signup.summary_breakdown.subheading')}
        </p>
      </div>

      <div className="">
        {!isLoading && (
          <ul className="text-gray-700 space-y-2 rounded-md mt-6 list-disc pl-4">
            {summaryLines.map((line, index) => (
              <li key={index} dangerouslySetInnerHTML={{ __html: line }} />
            ))}
          </ul>
        )}

        {isLoading && (
          <div className="animate-pulse mt-6 space-y-2">
            <div className="h-6 w-3/4 bg-gray-200" />
            <div className="h-6 w-3/4 bg-gray-200" />
            <div className="h-6 w-3/4 bg-gray-200" />
            <div className="h-6 w-3/4 bg-gray-200" />
          </div>
        )}
      </div>
    </>
  )
}
