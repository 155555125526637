import { Listbox, Transition } from '@headlessui/react'
import {
  CalendarIcon,
  SelectorIcon,
  TagIcon,
  SearchIcon,
  CheckIcon,
} from '@heroicons/react/outline'
import { DateTime } from 'luxon'
import { Fragment, useMemo, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import { kebabCase } from './BasketSlideover/BasketItem'
import { locale } from '../../app'

export const DateSelector = ({ value, onChange }) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <div className="w-full">
      <DatePicker
        selected={value}
        value={value}
        onChange={onChange}
        minDate={new Date()}
        onCalendarOpen={() => setOpen(true)}
        onCalendarClose={() => setOpen(false)}
        preventOpenOnFocus
        locale={locale}
        customInput={
          <button
            className={`w-full text-left p-3 text-gray-500 rounded-md flex items-center space-x-2 focus:outline-none focus:ring focus:ring-accent ${
              open ? 'ring ring-accent' : ''
            } group`}
          >
            <CalendarIcon
              className={`w-6 h-6 group-focus:text-accent ${open ? 'text-accent' : ''}`}
            />
            {value == null && <div className="flex-1">{t('frontend.hero.any_date')}</div>}
            {value !== null && (
              <div className="flex-1 text-gray-700">
                {DateTime.fromJSDate(value).toLocaleString(DateTime.DATE_MED)}
              </div>
            )}
            <SelectorIcon
              className={`w-6 h-6 group-focus:text-accent ${open ? 'text-accent' : ''}`}
            />
          </button>
        }
      />
    </div>
  )
}

export const DateSelectorMinDate = ({ value, onChange, minDate }) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <div className="w-full">
      <DatePicker
        selected={value}
        value={value}
        onChange={onChange}
        minDate={minDate}
        onCalendarOpen={() => setOpen(true)}
        onCalendarClose={() => setOpen(false)}
        preventOpenOnFocus
        locale={locale}
        customInput={
          <button
            className={`w-full text-left p-3 text-gray-500 rounded-md flex items-center space-x-2 focus:outline-none focus:ring focus:ring-accent ${open ? 'ring ring-accent' : ''} group`}
          >
            <CalendarIcon
              className={`w-6 h-6 group-focus:text-accent ${open ? 'text-accent' : ''}`}
            />
            {value == null && <div className="flex-1">{t('frontend.hero.any_date')}</div>}
            {value !== null && (
              <div className="flex-1 text-gray-700">
                {DateTime.fromJSDate(value).toLocaleString(DateTime.DATE_MED)}
              </div>
            )}
            <SelectorIcon
              className={`w-6 h-6 group-focus:text-accent ${open ? 'text-accent' : ''}`}
            />
          </button>
        }
      />
    </div>
  )
}

export const CategorySelector = ({ categories = [], value, onChange }) => {
  const { t } = useTranslation()

  return (
    <Listbox value={value} onChange={onChange} as="div" className="relative w-full">
      {({ open }) => (
        <>
          <Listbox.Button
            className={`text-left p-3 text-gray-500 rounded-md flex w-full items-center space-x-2 focus:outline-none focus:ring focus:ring-accent ${
              open ? 'ring ring-accent' : ''
            } group`}
          >
            <TagIcon className={`w-6 h-6 group-focus:text-accent ${open ? 'text-accent' : ''}`} />
            {value._id === null && <div className="flex-1">{t('frontend.hero.any_type')}</div>}
            {value._id !== null && <div className="flex-1 text-gray-700">{value.name}</div>}
            <SelectorIcon
              className={`w-6 h-6 group-focus:text-accent ${open ? 'text-accent' : ''}`}
            />
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute w-full p-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none">
              {categories.map((category, index) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) =>
                    `cursor-default select-none relative py-2 pl-10 pr-4 rounded-lg ${
                      active ? 'text-on-accent bg-accent' : 'text-gray-900'
                    }`
                  }
                  value={category}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                      >
                        {category.name}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                            active ? 'text-on-accent' : 'text-accent'
                          }`}
                        >
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </>
      )}
    </Listbox>
  )
}

export const HeroCta = ({
  categories,
  selectedCategoryId = null,
  selectedDateString,
  appliesToVoucherTypes = 'false',
}) => {
  const { t } = useTranslation()

  const appliesToVouchersType = appliesToVoucherTypes === 'false' ? false : true

  const emptyCategory = {
    _id: null,
    name: t('frontend.hero.any_type'),
  }

  const [selectedDate, setSelectedDate] = useState(() => {
    const existingDate = DateTime.fromFormat(selectedDateString, 'yyyy-MM-dd')

    // Ensure the passed date is valid and in the future.
    return existingDate.isValid && existingDate >= DateTime.local() ? existingDate.toJSDate() : null
  })

  const displayCategories = useMemo(
    () => [emptyCategory].concat(JSON.parse(categories)),
    [categories, emptyCategory]
  )
  const [selectedCategory, setSelectedCategory] = useState(
    displayCategories.find((cat) => cat._id === selectedCategoryId) ?? emptyCategory
  )

  const handleSearch = () => {
    let url = '/'

    if (selectedCategory._id !== null) {
      const categorySlug = kebabCase(selectedCategory.name)

      if (appliesToVouchersType) {
        url = `/vouchers/categories/${selectedCategory._id}/${categorySlug}`
      } else {
        url = `/offerings/categories/${selectedCategory._id}/${categorySlug}`
      }
    } else {
      if (appliesToVouchersType) {
        url = '/vouchers'

        window.location.href = `${url}`
      }
    }

    if (selectedDate) {
      const params = new URLSearchParams({
        date: DateTime.fromJSDate(selectedDate).toISODate(),
      })

      url = url.concat(`?${params.toString()}`)
    }

    window.location.href = `${url}#results`
  }

  return (
    <div className="absolute inset-0 z-10 m-12 sm:m-0 h-full flex items-center justify-center">
      <Transition
        as={Fragment}
        show={true}
        appear
        enter="transition ease-out duration-500"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
      >
        <div className="max-w-xl md:max-w-2xl w-full mx-auto bg-white rounded-lg shadow-2xl p-3 flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
          {!appliesToVouchersType && (
            <DateSelector value={selectedDate} onChange={setSelectedDate} />
          )}

          <CategorySelector
            value={selectedCategory}
            categories={displayCategories}
            onChange={setSelectedCategory}
          />

          <button
            title={t('frontend.hero.search')}
            className="shadow-sm p-3 text-on-accent bg-accent rounded-md flex items-center justify-center font-semibold space-x-2 px-4 focus:outline-none focus:ring focus:ring-accent focus:ring-offset-1"
            onClick={handleSearch}
          >
            <SearchIcon className="w-6 h-6" />
            <div>{t('frontend.hero.search')}</div>
          </button>
        </div>
      </Transition>
    </div>
  )
}
