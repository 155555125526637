import { DateTime } from 'luxon'
import { XIcon, CalendarIcon } from '@heroicons/react/solid'
import { useOffering } from './Context'
import { useState } from 'react'
import { Spinner } from '../Spinner'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export const OfferingSummaryV2 = ({ canClose = true, onClose }) => {
  const { offeringName, date, offeringImage } = useOffering()
  const [closing, setClosing] = useState(false)
  const { t } = useTranslation()

  const handleClose = () => {
    if (!canClose) return

    setClosing(true)
    onClose()
  }

  useEffect(() => {
    return () => setClosing(false)
  }, [])

  return (
    <div className="relative">
      <div
        className="min-h-[100px] bg-cover bg-center"
        style={offeringImage ? { backgroundImage: `url(${offeringImage})` } : {}}
      >
        <div className="relative backdrop-blur-sm">
          <div className="absolute inset-0 bg-black/25" />
          <div className="relative z-10 inset-x-0 bottom-0 p-6 px-8 mr-6">
            <div className="font-bold text-2xl text-white tracking-tight">{offeringName}</div>

            {date && (
              <div className="text-white font-medium items-center flex space-x-1">
                <CalendarIcon className="w-4 h-4" />
                <span>{date && date.toLocaleString(DateTime.DATE_MED)}</span>
              </div>
            )}
          </div>
          {canClose && (
            <div className="absolute top-0 right-0 p-3 z-10">
              <button
                className="focus:outline-none p-3 bg-white bg-opacity-10 text-white text-opacity-50 rounded-full hover:bg-opacity-20 hover:text-opacity-100"
                onClick={handleClose}
              >
                {closing && <Spinner margin={0} width="w-6" height="h-6" />}

                {!closing && (
                  <>
                    <span className="sr-only">
                      {t('frontend.availability_check.offering_summary.close')}
                    </span>
                    <XIcon className="h-6 w-6 " aria-hidden="true" />
                  </>
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
