import { RadioGroup } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import { formatDuration } from '../Duration'
import { formatMoney } from '../Money'

export const RateOption = ({ rate }) => {
  const { t } = useTranslation()

  return (
    <RadioGroup.Option
      className={({ checked }) =>
        `${
          checked ? 'ring ring-offset-1 ring-accent' : ''
        } rounded-md border border-gray-300 shadow-sm p-3 flex items-start bg-white`
      }
      value={rate.id}
    >
      {({ checked }) => (
        <>
          <div className="flex-1">
            <RadioGroup.Label className="text-sm font-medium truncate">
              {rate.name}
            </RadioGroup.Label>

            <RadioGroup.Description className="mt-2">
              <div className="text-lg font-medium">
                {formatMoney({ amount: rate.price, currency: rate.currency })}{' '}
                {t('frontend.become_a_member.rate_option.per')}{' '}
                {formatDuration(rate.billing_frequency)}
              </div>

              {rate.joining_fee > 0 && (
                <div className="text-sm text-gray-500">
                  {`+ ${formatMoney({ amount: rate.joining_fee, currency: rate.currency })} ${t(
                    'frontend.become_a_member.rate_option.joining_fee'
                  )}`}
                </div>
              )}
            </RadioGroup.Description>
          </div>

          {checked && (
            <div className="flex-shrink-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-7 w-7 text-accent"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          )}
        </>
      )}
    </RadioGroup.Option>
  )
}
