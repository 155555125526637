import { useOrder } from '../OrderHooks'
import { formatMoney } from '../Money'
import { SummaryList } from '../BasketSummaryList'
import { useTranslation } from 'react-i18next'

export const Contents = ({ onClose }) => {
  const { t } = useTranslation()

  const { isLoading, isError, data: { data: basket = {} } = {} } = useOrder()

  return (
    <>
      <div className="flex-1 py-6 px-4 sm:px-6 pt-0 overflow-y-auto">
        <SummaryList onClose={() => onClose()} />
      </div>
      {!isLoading && !isError && basket.items.length > 0 && (
        <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
          <div className="flex justify-between text-base font-medium text-gray-900">
            <p>{t('frontend.basket_slideover.contents.subtotal')}</p>
            <p>{formatMoney({ amount: basket.total_cost, currency: basket.currency })}</p>
          </div>
          <div className="mt-6">
            <a
              href="/checkout"
              className="flex justify-center items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-on-accent bg-accent hover:bg-accent-dark"
            >
              {t('frontend.basket_slideover.contents.checkout')}
            </a>
          </div>
          <div className="mt-6 flex justify-center text-sm text-center text-gray-500">
            <p>
              {t('frontend.basket_slideover.contents.or')}{' '}
              <button
                type="button"
                className="text-accent font-medium hover:text-accent-dark"
                onClick={() => onClose()}
              >
                {t('frontend.basket_slideover.contents.continue_shopping')}
                <span aria-hidden="true"> &rarr;</span>
              </button>
            </p>
          </div>
        </div>
      )}
    </>
  )
}
