import { useAddToOrder } from './OrderHooks'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Spinner } from './Spinner'
import { OfferingProvider, useOffering } from './AvailabilityCheck/Context'
import { OfferingModal } from './OfferingModal'
import { GuestConfig } from './AvailabilityCheck/GuestConfig'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSiteName } from '../useSiteName'

const queryClient = new QueryClient()

const Button = ({ maxGuests }) => {
  const { id, guests, price } = useOffering()
  const [showGuestConfigModal, setShowGuestConfigModal] = useState(false)
  const { t } = useTranslation()

  const { isLoading, mutate } = useAddToOrder(id, 'course')

  const handleAdd = (guests = null, callback = null) => {
    mutate(
      { guests },
      {
        onSuccess: (e) => {
          const item = e.items.find((item) => item.offering_id === id)
          const numberOfGuests = guests?.length ?? 1

          if (window.gtag) {
            window.gtag('event', 'add_to_cart', {
              currency: e.currency.toUpperCase(),
              value: parseFloat(((price / 100).toFixed(2) * numberOfGuests).toFixed(2)),
              items: [
                {
                  affiliation: useSiteName(),
                  item_id: item.offering_id,
                  item_name: item.offering_name,
                  quantity: numberOfGuests,
                  price: parseFloat((item.price / 100).toFixed(2)),
                },
              ],
            })
          }

          window.openSlideover()
          window.setBasket(e)

          if (callback) callback()
        },
      }
    )
  }

  const handleClick = () => {
    if (guests && guests.filter((guest) => guest.name).length > 0) {
      setShowGuestConfigModal(true)
    } else {
      handleAdd()
    }
  }

  const handleContinue = (guests) => {
    handleAdd(guests, () => setShowGuestConfigModal(false))
  }

  return (
    <>
      <button
        className="relative bg-accent overflow-hidden w-full text-sm text-on-accent font-medium p-3 rounded-md hover:bg-accent/10"
        onClick={handleClick}
        disabled={isLoading}
      >
        {t('frontend.add_course_button.add_to_order')}
        {isLoading && (
          <div className="absolute inset-0 bg-accent flex justify-center items-center">
            <Spinner width="m-5" height="h-5" />
          </div>
        )}
      </button>

      <OfferingModal isOpen={showGuestConfigModal} onClose={() => setShowGuestConfigModal(false)}>
        <GuestConfig
          onComplete={handleContinue}
          ctaButtonText={t('frontend.add_course_button.add_to_order')}
          maxGuests={maxGuests}
        />
      </OfferingModal>
    </>
  )
}

export const AddCourseButton = (props) => (
  <QueryClientProvider client={queryClient}>
    <OfferingProvider
      id={props.offeringId}
      basketGuests={props.guests ? JSON.parse(props.guests) : []}
      price={props.price}
      maxGuests={props.maxGuests}
    >
      <Button />
    </OfferingProvider>
  </QueryClientProvider>
)
