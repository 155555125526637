import { TimeSlotButton } from './TimeSlotPickerV2'

export const SuggestedSlotPicker = ({
  onSelect,
  slots,
  disabled = false,
  showPrice = false,
  showCapacity = false,
}) => {
  return (
    <div className="">
      <div className="divide-y divide-gray-200">
        {slots.map((slot) => (
          <TimeSlotButton
            slot={slot}
            key={`slot_${slot.start_time}_${slot.duration}`}
            onSelect={onSelect}
            disabled={disabled}
            showPrice={showPrice}
            showCapacity={showCapacity}
            variant="highlight"
          />
        ))}
      </div>
    </div>
  )
}
