import { Fragment, useEffect, useState, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { QueryClientProvider } from 'react-query'
import { Contents } from './Contents.jsx'
import { getQueryClient } from '../OrderHooks'
import { useTranslation } from 'react-i18next'

const queryClient = getQueryClient()

export const BasketSlideover = ({ initBasket = '{}', initItemCount = 0 }) => {
  const [open, setOpen] = useState(false)
  const [basket, setBasket] = useState(JSON.parse(initBasket))
  const [itemCount, setItemCount] = useState(initItemCount)
  const closeBtn = useRef(null)
  const { t } = useTranslation()

  useEffect(() => {
    if (basket?.items) {
      setItemCount(
        basket.items.filter((item) => item.item_configuration?.share_basket_item !== true).length ??
          0
      )
    }
  }, [basket])

  const openSlideover = () => {
    setTimeout(() => {
      // https://app.shortcut.com/trybeapp/story/6748
      // Open it after any previously opened dialogs have
      // had their closing event initialised
      setOpen(true)
    }, 300)
  }

  window.openSlideover = openSlideover
  window.setBasket = (basket) => {
    window.basket = basket
    setBasket(basket)
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden z-40"
          initialFocus={closeBtn}
          onClose={setOpen}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-y-0 right-0 pl-10 max-w-md flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-sm">
                  <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                    <Dialog.Title className="py-6 px-4 sm:px-6 block text-lg font-medium text-gray-900 text-center bg-white bg-opacity-50 backdrop-blur-md">
                      <div>{t('frontend.basket.slideover.title')}</div>
                      <div className="text-sm text-gray-500 mt-2">
                        {t('frontend.basket.slideover.items_count', {
                          count: itemCount,
                        })}
                      </div>
                    </Dialog.Title>
                    <QueryClientProvider client={queryClient}>
                      <Contents onClose={() => setOpen(false)} />
                    </QueryClientProvider>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <button
        onClick={() => setOpen(true)}
        type="button"
        className="relative group -ml-2 p-2 flex items-center rounded-md"
      >
        <span className="sr-only">{t('frontend.basket_slideover.index.open_menu')}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
          />
        </svg>
        <span className="bg-accent absolute top-0 right-0 w-4 aspect-square text-center text-xs rounded-full font-semibold text-on-accent">
          {itemCount}
        </span>
        <span className="sr-only">{t('frontend.basket_slideover.index.items_in_cart')}</span>
      </button>
    </>
  )
}
