import { useTranslation } from 'react-i18next'

export const formatFromISODuration = (durationString) => {
  const { t } = useTranslation()

  switch (durationString) {
    case 'P1M':
      return t('frontend.format_duration.month')
    case 'P3M':
      return t('frontend.format_duration.quarter')
    case 'P6M':
      return t('frontend.format_duration.six_months')
    case 'P1Y':
      return t('frontend.format_duration.year')
    default:
      return durationString
  }
}

export const useFormatFromNumberOfMinutes = (minutes, isMax = false) => {
  const prefix = isMax ? 'max_' : 'lasts_'
  const { t } = useTranslation()

  switch (true) {
    case minutes < 60:
      return t(`frontend.duration.${prefix}minutes`, {
        count: parseInt(minutes),
        minutes: `${minutes}`,
      })
    case minutes % 60 === 0:
      return t(`frontend.duration.${prefix}hours`, { count: minutes / 60, hours: minutes / 60 })
    default:
      const hours = Math.floor(minutes / 60)
      minutes = minutes % 60

      return t(`frontend.duration.${prefix}hours_and_minutes`, { count: hours, hours, minutes })
  }
}

/**
 * @deprecated Use formatFromISODuration instead
 */
export const formatDuration = (durationString) => {
  return formatFromISODuration(durationString)
}
