import { Modal, ModalInner } from './Modal';
import React from 'react';
import { DateTime } from 'luxon';
import { useGetVoucherDeliveryOption } from './OrderHooks';
import { formatMoney } from './Money';
import { useTranslation } from 'react-i18next';
export var VoucherDeliveryDetailsModal = function (_a) {
    var onClose = _a.onClose, item = _a.item, isOpen = _a.isOpen;
    var PHYSICAL_DELIVERY_METHOD = 'physical';
    var t = useTranslation().t;
    var EmailDeliveryDetails = function () {
        var date = item.item_configuration['delivery_date'] !== null
            ? DateTime.fromISO(item.item_configuration['delivery_date'])
            : null;
        return (<div>
        <div className="my-3">
          <div className="mb-2">
            {item.item_configuration['recipient_email']}
            {date !== null
                ? t('frontend.voucher_delivery.delivery_date', {
                    date: date.toLocaleString(DateTime.DATE_MED),
                })
                : ''}
          </div>
        </div>
      </div>);
    };
    var PhysicalDeliveryDetails = function () {
        var _a;
        var _b = useGetVoucherDeliveryOption(item.item_configuration['delivery_option_id']), isLoading = _b.isLoading, isSuccess = _b.isSuccess, _c = _b.data, _d = _c === void 0 ? {} : _c, voucherDeliveryOption = _d.data;
        return (<div>
        <div>{item.item_configuration['address_line1']}</div>
        <div>{item.item_configuration['address_line2']}</div>
        <div>{item.item_configuration['town']}</div>
        <div>{item.item_configuration['postcode']}</div>
        <div>{item.item_configuration['country']}</div>

        {isSuccess && (<div className="my-3">
            {voucherDeliveryOption.name} @{' '}
            {formatMoney({
                    amount: voucherDeliveryOption.price,
                    currencey: (_a = voucherDeliveryOption.currency) !== null && _a !== void 0 ? _a : 'GBP',
                })}
          </div>)}
      </div>);
    };
    var DeliveryDetailsSection = function () {
        if (item.item_configuration['delivery_method_type'] === PHYSICAL_DELIVERY_METHOD) {
            return (<div>
          <PhysicalDeliveryDetails />
        </div>);
        }
        return (<div>
        <EmailDeliveryDetails />
      </div>);
    };
    return (<Modal open={isOpen} onClose={onClose} styleReset>
      <ModalInner>
        <div className="p-8">
          <div className="mb-2">{t('frontend.voucher_delivery.delivery_details')}</div>
          <div>
            <DeliveryDetailsSection />
          </div>
        </div>
      </ModalInner>
    </Modal>);
};
