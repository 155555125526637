import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const GuestIntakeMultipleChoice = ({
  required,
  id,
  questionType,
  multipleSelection,
  multipleSelectOption,
  additionalDetails,
  exactNumber,
  rangeFrom,
  rangeTo,
  label,
  choices,
}) => {
  const { t } = useTranslation()
  const [selectedRadio, setSelectedRadio] = useState(null)
  const [selected, setSelected] = useState([])
  const exactLimit = +exactNumber <= selected.length
  const rangeLimit = selected.length >= +rangeTo
  const choicesList = JSON.parse(choices)

  const handleChexboxChange = (event, item) => {
    if (event.target.checked) {
      setSelected([...selected, item])
    } else {
      setSelected((prev) => prev.filter((currItem) => currItem !== item))
    }
  }

  return (
    <>
      <fieldset>
        <div className="flex justify-between items-start">
          <legend className={`text-sm font-medium text-black mb-1`}>{label}</legend>
          {required && (
            <span className={`bg-gray-100 text-gray-400 rounded-md text-xs uppercase p-0.5 px-1.5`}>
              {t('frontend.form.required')}
            </span>
          )}
        </div>
        <div>
          {multipleSelectOption === 'exact_number' &&
            exactLimit > 1 &&
            +exactNumber !== selected.length && (
              <p className="text-xs text-gray-400">
                {t('frontend.guest_intake_multiple_choice.choose_x', {
                  number: exactNumber,
                })}
              </p>
            )}

          {multipleSelectOption === 'exact_number' && exactLimit && (
            <p className="text-xs text-green-400">
              {t('frontend.guest_intake_multiple_choice.perfect')}
            </p>
          )}

          {multipleSelectOption === 'range' && selected.length < +rangeTo && (
            <p className="text-xs text-gray-400">
              {t('frontend.guest_intake_multiple_choice.make_between_x_choices', {
                from: rangeFrom,
                to: rangeTo,
              })}
            </p>
          )}

          {multipleSelectOption === 'range' && rangeLimit && (
            <p className="text-xs text-green-400">
              {t('frontend.guest_intake_multiple_choice.thanks')}
            </p>
          )}

          {multipleSelectOption === 'unlimited' && (
            <p className="text-xs text-gray-400">
              {t('frontend.guest_intake_multiple_choice.choose_any_that_apply')}
            </p>
          )}
        </div>

        <div>
          {choicesList.map((choice, index) => {
            if (multipleSelection) {
              if (multipleSelectOption === 'exact_number') {
                return (
                  <div className="flex items-start space-x-2 mt-2" key={index}>
                    <div className="flex-shrink-0 contents">
                      <input
                        className="focus:ring-accent h-4 w-4 text-accent border-gray-300 rounded relative top-1"
                        type="checkbox"
                        id={`${choice.label}`}
                        name={`answers[${id}][customer_response_${questionType}][${index}]`}
                        value={`${choice.label}`}
                        required={required}
                        onChange={(event) => handleChexboxChange(event, choice.label)}
                        disabled={exactLimit && !selected.includes(choice.label)}
                      />
                    </div>
                    <label
                      className={`ml-2 flex-1 font-medium ${
                        exactLimit && !selected.includes(choice.label)
                          ? `text-gray-400`
                          : `text-gray-700`
                      }`}
                      htmlFor={`${choice.label}`}
                    >
                      {choice.label}
                    </label>
                    <br />
                  </div>
                )
              }

              if (multipleSelectOption === 'range') {
                return (
                  <div className="flex items-start space-x-2 mt-2" key={index}>
                    <div className="flex-shrink-0 contents">
                      <input
                        className="focus:ring-accent h-4 w-4 text-accent border-gray-300 rounded relative top-1"
                        type="checkbox"
                        id={`${choice.label}`}
                        name={`answers[${id}][customer_response_${questionType}][${index}]`}
                        value={`${choice.label}`}
                        required={required && selected.length < rangeFrom}
                        onChange={(event) => handleChexboxChange(event, choice.label)}
                        disabled={rangeLimit && !selected.includes(choice.label)}
                      />
                    </div>
                    <label
                      className={`ml-2 flex-1 font-medium ${
                        rangeLimit && !selected.includes(choice.label)
                          ? `text-gray-400`
                          : `text-gray-700`
                      }`}
                      htmlFor={`${choice.label}`}
                    >
                      {choice.label}
                    </label>
                    <br />
                  </div>
                )
              }

              if (multipleSelectOption === 'unlimited') {
                return (
                  <div className="flex items-start space-x-2 mt-2" key={index}>
                    <div className="flex-shrink-0 contents">
                      <input
                        className="focus:ring-accent h-4 w-4 text-accent border-gray-300 rounded relative top-1"
                        type="checkbox"
                        id={`${choice.label}`}
                        name={`answers[${id}][customer_response_${questionType}][${index}]`}
                        value={`${choice.label}`}
                        required={required && selected.length < 1}
                        onChange={(event) => handleChexboxChange(event, choice.label)}
                      />
                    </div>
                    <label
                      className="ml-2 flex-1 font-medium text-gray-700"
                      htmlFor={`${choice.label}`}
                    >
                      {choice.label}
                    </label>
                    <br />
                  </div>
                )
              }
            }

            return (
              <div key={index}>
                <input
                  className="border-gray-300 relative -top-0.5 text-accent focus:outline-none focus:ring focus:ring-accent"
                  type="radio"
                  id={`${choice.label}`}
                  name={`answers[${id}][customer_response_${questionType}]`}
                  value={`${choice.label}`}
                  required={required}
                  checked={selectedRadio === choice.label}
                  onChange={() => setSelectedRadio(choice.label)}
                />
                <label
                  className="ml-2 flex-1 font-medium text-gray-700"
                  htmlFor={`${choice.label}`}
                >
                  {choice.label}
                </label>
                <br />
              </div>
            )
          })}
        </div>
        {additionalDetails && (selected.length > 0 || selectedRadio !== null) && (
          <div className="mt-2">
            <div className="mb-2">
              <label htmlFor="additional_details" className="text-sm font-medium text-black">
                {t('frontend.guest_intake_multiple_choice.provide_further_info')}
              </label>
            </div>
            <textarea
              id="additional_details"
              className="block p-2.5 w-full text-sm text-gray-700 bg-gray-50 rounded-lg border border-gray-300 focus:ring-black focus:border-black"
              name={`answers[${id}][additional_details]`}
            />
          </div>
        )}
      </fieldset>
    </>
  )
}
