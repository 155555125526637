import { Modal, ModalInner } from './Modal'
import { OfferingSummaryV2 } from './AvailabilityCheck/OfferingSummaryV2'
import { OfferingSummary } from './AvailabilityCheck/OfferingSummary'

export const OfferingModal = ({ size = 'max-w-xl', isOpen, onClose, children }) => (
  <Modal size={size} open={isOpen} onClose={onClose}>
    <ModalInner>
      <div className="flex min-h-screen flex-col sm:min-h-0">
        {window.featureFlags.includes('package_config_tweaks') && (
          <OfferingSummaryV2 onClose={onClose} />
        )}

        {!window.featureFlags.includes('package_config_tweaks') && (
          <OfferingSummary onClose={onClose} />
        )}
        <div className="flex-1 sm:flex-auto">{children}</div>
      </div>
    </ModalInner>
  </Modal>
)
