var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CheckAvailabilityApi, ShopApi } from '@trybeapp/sdk.shop';
import { useMutation, useQuery, useQueryClient, QueryClient } from 'react-query';
import { DateTime } from 'luxon';
import { useOffering } from './AvailabilityCheck/Context';
import { AvailabilityApi } from '@trybeapp/sdk';
import { apiConfiguration } from '../../utilities/TrybeApiClient';
export var headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};
export var frontendBaseUrl = 'https://' + window.location.hostname;
var api = new CheckAvailabilityApi();
var availabilityApi = new AvailabilityApi(apiConfiguration);
export var getQueryClient = function () {
    if (window.queryClient === undefined) {
        window.queryClient = new QueryClient();
    }
    return window.queryClient;
};
export var useAddToVisitOrder = function (offeringId, offeringType, basketId) {
    var queryClient = useQueryClient();
    return useMutation(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var payload, req;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    payload = {
                        basket_id: basketId,
                        offering_id: (_a = values === null || values === void 0 ? void 0 : values.offeringId) !== null && _a !== void 0 ? _a : offeringId,
                        offering_type: (_b = values === null || values === void 0 ? void 0 : values.offeringType) !== null && _b !== void 0 ? _b : offeringType,
                    };
                    if (values === null || values === void 0 ? void 0 : values.selectedDate) {
                        payload.date = values === null || values === void 0 ? void 0 : values.selectedDate.toISODate();
                    }
                    if (values === null || values === void 0 ? void 0 : values.time) {
                        payload.time = DateTime.fromJSDate(values.time).toISO({ suppressMilliseconds: true });
                    }
                    if (values === null || values === void 0 ? void 0 : values.duration) {
                        payload.duration = values === null || values === void 0 ? void 0 : values.duration;
                    }
                    if (values === null || values === void 0 ? void 0 : values.guest_id) {
                        payload.guest_id = values === null || values === void 0 ? void 0 : values.guest_id;
                    }
                    if (values === null || values === void 0 ? void 0 : values.guest_ids) {
                        payload.guest_ids = values === null || values === void 0 ? void 0 : values.guest_ids;
                    }
                    if (values === null || values === void 0 ? void 0 : values.guests) {
                        payload.guests = values === null || values === void 0 ? void 0 : values.guests;
                    }
                    if (values === null || values === void 0 ? void 0 : values.item_configuration) {
                        payload.item_configuration = values === null || values === void 0 ? void 0 : values.item_configuration;
                    }
                    return [4 /*yield*/, fetch(frontendBaseUrl + '/api/my-visit/basket/add-item', {
                            method: 'post',
                            headers: headers,
                            body: JSON.stringify(payload),
                            credentials: 'include',
                        })];
                case 1:
                    req = _c.sent();
                    if (!(req.status > 299)) return [3 /*break*/, 3];
                    return [4 /*yield*/, req.json()];
                case 2: throw _c.sent();
                case 3: return [4 /*yield*/, req.json()];
                case 4: return [2 /*return*/, (_c.sent()).data];
            }
        });
    }); }, {
        onSuccess: function () {
            queryClient.invalidateQueries([
                'availableTimeslots',
                {
                    id: offeringId,
                    offeringType: offeringType,
                },
            ]);
        },
    });
};
export var useAddToOrder = function (offeringId, offeringType, basketId) {
    var queryClient = useQueryClient();
    return useMutation(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var payload, req;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    payload = {
                        basket_id: basketId,
                        offering_id: (_a = values === null || values === void 0 ? void 0 : values.offeringId) !== null && _a !== void 0 ? _a : offeringId,
                        offering_type: (_b = values === null || values === void 0 ? void 0 : values.offeringType) !== null && _b !== void 0 ? _b : offeringType,
                    };
                    if (values === null || values === void 0 ? void 0 : values.selectedDate) {
                        payload.date = values === null || values === void 0 ? void 0 : values.selectedDate.toISODate();
                    }
                    if (values === null || values === void 0 ? void 0 : values.time) {
                        payload.time = DateTime.fromJSDate(values.time).toISO({ suppressMilliseconds: true });
                    }
                    if (values === null || values === void 0 ? void 0 : values.duration) {
                        payload.duration = values === null || values === void 0 ? void 0 : values.duration;
                    }
                    if (values === null || values === void 0 ? void 0 : values.guests) {
                        payload.guests = values === null || values === void 0 ? void 0 : values.guests;
                    }
                    if (values === null || values === void 0 ? void 0 : values.guest_ids) {
                        payload.guest_ids = values === null || values === void 0 ? void 0 : values.guest_ids;
                    }
                    if (values === null || values === void 0 ? void 0 : values.item_configuration) {
                        payload.item_configuration = values === null || values === void 0 ? void 0 : values.item_configuration;
                    }
                    return [4 /*yield*/, fetch(frontendBaseUrl + '/basket/add-item', {
                            method: 'post',
                            headers: headers,
                            body: JSON.stringify(payload),
                            credentials: 'include',
                        })];
                case 1:
                    req = _c.sent();
                    if (!(req.status > 299)) return [3 /*break*/, 3];
                    return [4 /*yield*/, req.json()];
                case 2: throw _c.sent();
                case 3: return [4 /*yield*/, req.json()];
                case 4: return [2 /*return*/, (_c.sent()).data];
            }
        });
    }); }, {
        onSuccess: function () {
            queryClient.invalidateQueries([
                'availableTimeslots',
                {
                    id: offeringId,
                    offeringType: offeringType,
                },
            ]);
        },
    });
};
export var useRemoveFromOrder = function (basketId) {
    var queryClient = useQueryClient();
    return useMutation(function (basketItemId) { return __awaiter(void 0, void 0, void 0, function () {
        var url, req;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = frontendBaseUrl + "/basket/remove-item/" + basketItemId;
                    if (basketId) {
                        url += "?basket_id=" + basketId;
                    }
                    return [4 /*yield*/, fetch(url, {
                            method: 'post',
                            headers: headers,
                            credentials: 'include',
                        })];
                case 1:
                    req = _a.sent();
                    if (req.status > 299)
                        throw req;
                    queryClient.invalidateQueries(['basket']);
                    return [2 /*return*/];
            }
        });
    }); });
};
export var useOrder = function (options) {
    if (options === void 0) { options = {}; }
    return useQuery(['basket'], function () { return __awaiter(void 0, void 0, void 0, function () {
        var req, json;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch(frontendBaseUrl + '/basket', {
                        credentials: 'include',
                        headers: headers,
                    })];
                case 1:
                    req = _a.sent();
                    if (req.status > 499)
                        throw req;
                    return [4 /*yield*/, req.json()];
                case 2:
                    json = _a.sent();
                    if (window.setBasket !== undefined) {
                        window.setBasket(json.data);
                    }
                    return [2 /*return*/, json];
            }
        });
    }); }, options);
};
export var useMemberLookup = function (membershipNumber, lastName, options) {
    var _a;
    if (options === void 0) { options = {}; }
    options.retry = (_a = options.retry) !== null && _a !== void 0 ? _a : false;
    return useQuery(['memberSearch', { membershipNumber: membershipNumber, lastName: lastName }], function () { return __awaiter(void 0, void 0, void 0, function () {
        var params, req, json;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = new URLSearchParams({
                        membership_number: membershipNumber,
                        last_name: lastName,
                    });
                    return [4 /*yield*/, fetch(frontendBaseUrl + '/member-lookup?' + params, {
                            credentials: 'include',
                            headers: headers,
                        })];
                case 1:
                    req = _a.sent();
                    if (req.status >= 400)
                        throw req;
                    return [4 /*yield*/, req.json()];
                case 2:
                    json = _a.sent();
                    return [2 /*return*/, json];
            }
        });
    }); }, options);
};
export var useGetVoucherDeliveryOptions = function (options) {
    var _a;
    if (options === void 0) { options = {}; }
    options.retry = (_a = options.retry) !== null && _a !== void 0 ? _a : false;
    return useQuery(['voucherDeliveryOptionsFetch'], function () { return __awaiter(void 0, void 0, void 0, function () {
        var req, json;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch(frontendBaseUrl + '/voucher-delivery-options?', {
                        credentials: 'include',
                        headers: headers,
                    })];
                case 1:
                    req = _a.sent();
                    if (req.status >= 400)
                        throw req;
                    return [4 /*yield*/, req.json()];
                case 2:
                    json = _a.sent();
                    return [2 /*return*/, json];
            }
        });
    }); }, options);
};
export var useGetVoucherDeliveryOption = function (voucherId, options) {
    var _a;
    if (options === void 0) { options = {}; }
    options.retry = (_a = options.retry) !== null && _a !== void 0 ? _a : false;
    return useQuery(['voucherDeliveryOptionFetch', { voucherId: voucherId }], function () { return __awaiter(void 0, void 0, void 0, function () {
        var req;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch(frontendBaseUrl + ("/voucher-delivery-options/" + voucherId), {
                        credentials: 'include',
                        headers: headers,
                    })];
                case 1:
                    req = _a.sent();
                    if (req.status >= 400)
                        throw req;
                    return [4 /*yield*/, req.json()];
                case 2: return [2 /*return*/, _a.sent()];
            }
        });
    }); }, options);
};
export var useAvailableTimeslots = function (duration, basketId, options) {
    if (duration === void 0) { duration = null; }
    if (basketId === void 0) { basketId = null; }
    if (options === void 0) { options = {}; }
    var _a = useOffering(), id = _a.id, offeringType = _a.offeringType, siteId = _a.siteId, date = _a.date;
    return useQuery(['availableTimeslots', { id: id, offeringType: offeringType, siteId: siteId, duration: duration, date: date.toISO(), basketId: basketId }], function () {
        var params = {
            dateTimeFrom: date.startOf('day').toISO({ suppressMilliseconds: true }),
            dateTimeTo: date.endOf('day').startOf('second').toISO({ suppressMilliseconds: true }),
        };
        if (basketId) {
            params.basketId = basketId;
        }
        switch (offeringType) {
            case 'appointment':
                return api.getAppointmentAvailableSlots(siteId, id, params);
            case 'session':
                return api.getSessionAvailability(siteId, id, params);
            case 'area_booking':
                return api.getAreaBookingSlots(id, __assign(__assign({}, params), { duration: duration }));
        }
    }, options);
};
export var useAvailableDates = function (dateFrom) {
    var _a = useOffering(), id = _a.id, offeringType = _a.offeringType, numberOfGuests = _a.numberOfGuests;
    return useQuery(['availableDates', { id: id, dateFrom: dateFrom.toISODate(), guests: numberOfGuests }], function () {
        return api.getOfferingDatesAvailability(id, offeringType, dateFrom.toISODate(), dateFrom.endOf('month').toISODate(), {
            quantity: numberOfGuests,
        });
    }, {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });
};
export var useCheckAppointmentEnquiry = function (dateFrom, dateTo, options) {
    var appointmentTypeId = useOffering().id;
    return useQuery([
        'appointmentEnquiryCheck',
        {
            appointmentTypeId: appointmentTypeId,
            dateFrom: dateFrom,
            dateTo: dateTo,
        },
    ], function () {
        return availabilityApi.actionCheckAppointmentEnquiryAvailability({
            appointmentTypeId: appointmentTypeId,
            dateTimeFrom: dateFrom.toISO({ suppressMilliseconds: true }),
            dateTimeTo: dateTo.toISO({ suppressMilliseconds: true }),
        });
    }, __assign(__assign({}, options), { refetchOnWindowFocus: false, refetchOnMount: false }));
};
export var useTotalsForMembershipRate = function (rateId) {
    return useQuery(['totalsForRate', { rateId: rateId }], function () { return __awaiter(void 0, void 0, void 0, function () {
        var req;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch(frontendBaseUrl + "/become-a-member/rate-totals/" + rateId, {
                        credentials: 'include',
                        headers: headers,
                    })];
                case 1:
                    req = _a.sent();
                    return [2 /*return*/, req.json()];
            }
        });
    }); });
};
var shopApi = new ShopApi();
export var usePackage = function (packageId, siteId, options) {
    if (options === void 0) { options = {}; }
    return useQuery(['package', { packageId: packageId }], function () { return shopApi.getShopOffering('package', packageId, siteId); }, options);
};
export var usePackageSlots = function (packageId, choiceId, date, basketId, basketItemId, optionId, options) {
    if (options === void 0) { options = {}; }
    return useQuery([
        'packageSlots',
        { packageId: packageId, choiceId: choiceId, date: date.toISODate(), basketId: basketId, basketItemId: basketItemId, optionId: optionId },
    ], function () {
        return api.getPackageChoiceSlotAvailability(packageId, choiceId, date.toISODate(), {
            basketId: basketId,
            basketItemId: basketItemId,
            optionId: optionId,
        });
    }, options);
};
export var useUpdatePackageItem = function (basketId) {
    var queryClient = useQueryClient();
    return useMutation(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var req;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch(frontendBaseUrl + "/basket/add-package-item/" + values.basketItemId, {
                        credentials: 'include',
                        headers: headers,
                        method: 'post',
                        body: JSON.stringify(__assign(__assign({}, values), { basket_id: basketId })),
                    })];
                case 1:
                    req = _a.sent();
                    if (!(req.status > 299)) return [3 /*break*/, 3];
                    return [4 /*yield*/, req.json()];
                case 2: throw _a.sent();
                case 3: return [4 /*yield*/, req.json()];
                case 4: return [2 /*return*/, (_a.sent()).data];
            }
        });
    }); }, {
        onSuccess: function (data) {
            queryClient.invalidateQueries(['packageSlots', 'basket']);
            if (window.setBasket !== undefined) {
                window.setBasket(data);
            }
        },
    });
};
export var useUpdateVisitPackageItem = function (basketId) {
    var queryClient = useQueryClient();
    return useMutation(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var req;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch(frontendBaseUrl + "/api/my-visit/basket/add-package-item/" + values.basketItemId, {
                        credentials: 'include',
                        headers: headers,
                        method: 'post',
                        body: JSON.stringify(__assign(__assign({}, values), { basket_id: basketId })),
                    })];
                case 1:
                    req = _a.sent();
                    if (!(req.status > 299)) return [3 /*break*/, 3];
                    return [4 /*yield*/, req.json()];
                case 2: throw _a.sent();
                case 3: return [4 /*yield*/, req.json()];
                case 4: return [2 /*return*/, (_a.sent()).data];
            }
        });
    }); }, {
        onSuccess: function (data) {
            queryClient.invalidateQueries(['packageSlots', 'basket']);
            if (window.setBasket !== undefined) {
                window.setBasket(data);
            }
        },
    });
};
export var useDeletePackageItem = function (basketId) {
    var queryClient = useQueryClient();
    return useMutation(function (_a) {
        var basketItemId = _a.basketItemId, choiceId = _a.choiceId;
        return __awaiter(void 0, void 0, void 0, function () {
            var req;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, fetch(frontendBaseUrl + "/basket/remove-package-item/" + basketItemId, {
                            credentials: 'include',
                            headers: headers,
                            method: 'post',
                            body: JSON.stringify({ choice_id: choiceId, basket_id: basketId }),
                        })];
                    case 1:
                        req = _b.sent();
                        if (!(req.status > 299)) return [3 /*break*/, 3];
                        return [4 /*yield*/, req.json()];
                    case 2: throw _b.sent();
                    case 3: return [4 /*yield*/, req.json()];
                    case 4: return [2 /*return*/, (_b.sent()).data];
                }
            });
        });
    }, {
        onSuccess: function () {
            queryClient.invalidateQueries(['packageSlots']);
            queryClient.invalidateQueries(['basket']);
        },
    });
};
export var useDeletePackageItemOption = function (basketId) {
    var queryClient = useQueryClient();
    return useMutation(function (_a) {
        var basketItemId = _a.basketItemId, itemId = _a.itemId;
        return __awaiter(void 0, void 0, void 0, function () {
            var req;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, fetch(frontendBaseUrl + "/basket/remove-package-item-option/" + basketItemId, {
                            credentials: 'include',
                            headers: headers,
                            method: 'post',
                            body: JSON.stringify({ item_id: itemId, basket_id: basketId }),
                        })];
                    case 1:
                        req = _b.sent();
                        if (!(req.status > 299)) return [3 /*break*/, 3];
                        return [4 /*yield*/, req.json()];
                    case 2: throw _b.sent();
                    case 3: return [4 /*yield*/, req.json()];
                    case 4: return [2 /*return*/, (_b.sent()).data];
                }
            });
        });
    }, {
        onSuccess: function () {
            queryClient.invalidateQueries(['packageSlots']);
            queryClient.invalidateQueries(['basket']);
        },
    });
};
export var useUpdateItem = function () {
    var queryClient = useQueryClient();
    return useMutation(function (_a) {
        var basketItemId = _a.basketItemId, values = _a.values;
        return __awaiter(void 0, void 0, void 0, function () {
            var req;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, fetch(frontendBaseUrl + "/basket/update-item/" + basketItemId, {
                            credentials: 'include',
                            headers: headers,
                            method: 'post',
                            body: JSON.stringify(values),
                        })];
                    case 1:
                        req = _b.sent();
                        if (!(req.status > 299)) return [3 /*break*/, 3];
                        return [4 /*yield*/, req.json()];
                    case 2: throw _b.sent();
                    case 3: return [4 /*yield*/, req.json()];
                    case 4: return [2 /*return*/, (_b.sent()).data];
                }
            });
        });
    }, {
        onSuccess: function (resp) {
            if (window.setBasket !== undefined) {
                window.setBasket(resp);
            }
            queryClient.invalidateQueries(['basket']);
            queryClient.invalidateQueries(['availableTimeslots']);
        },
    });
};
export var useCancelItem = function (orderId, itemId, token) {
    return useMutation(function () { return __awaiter(void 0, void 0, void 0, function () {
        var req;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch(frontendBaseUrl + "/my-account/orders/" + orderId + "/items/" + itemId + "/cancel", {
                        credentials: 'include',
                        headers: headers,
                        method: 'post',
                        body: JSON.stringify({ token: token }),
                    })];
                case 1:
                    req = _a.sent();
                    if (req.status > 299)
                        throw new Error('Cancel failed');
                    return [2 /*return*/, true];
            }
        });
    }); });
};
