import { useState, useEffect } from 'react'
import Toggle from './Toggle'
import { useTranslation } from 'react-i18next'

const ToggleUnavailableOfferings = () => {
  const [isToggled, setIsToggled] = useState(false)
  const { t } = useTranslation()

  const getUrlParams = () => {
    const url = new URL(window.location.href)
    return new URLSearchParams(url.search)
  }

  useEffect(() => {
    // When the component is mounted, check if the 'hide_offerings' query parameter is present
    const searchParams = getUrlParams()
    const hideOfferings = searchParams.get('hide_offerings')
    const elements = document.querySelectorAll('[data-offering-card]')
    const availabilityDate = searchParams.get('date')

    elements.forEach((element) => {
      const availability = element.getAttribute('data-availability')
      if (availability === 'false' && hideOfferings === null && availabilityDate !== null) {
        element.classList.add('opacity-50')
      }

      if (hideOfferings === 'true' && availability === 'false') {
        element.classList.add('hidden')
      }

      if (hideOfferings === 'false' && availability === 'false' && availabilityDate !== null) {
        element.classList.add('opacity-50')
      }
    })
    if (hideOfferings === 'true') {
      setIsToggled(true)
    }
  }, [])

  const handleClick = () => {
    // Get the current URL and its search parameters
    const url = new URL(window.location.href)
    const searchParams = getUrlParams()

    // Toggle the 'hide_offerings' query parameter
    if (isToggled) {
      searchParams.set('hide_offerings', 'false')
    } else {
      searchParams.set('hide_offerings', 'true')
    }

    // Set the updated search parameters back to the URL
    url.search = searchParams.toString()
    url.hash = 'results'
    window.history.pushState({}, '', url.toString())

    setIsToggled(!isToggled)

    const elements = document.querySelectorAll('[data-offering-card]')
    elements.forEach((element) => {
      const availability = element.getAttribute('data-availability')

      if (availability === 'false') {
        element.setAttribute('data-offering-card', isToggled ? 'true' : 'false')

        if (isToggled) {
          element.classList.remove('hidden')
          element.classList.add('opacity-50')
        } else {
          element.classList.add('hidden')
        }
      }
    })
  }

  return (
    <>
      {window.featureFlags.includes('toggle_unavailable_offerings') && (
        <div className="flex items-center">
          <Toggle handleClick={handleClick} />
          <span className="ml-3 text-gray-500 text-sm">
            {isToggled
              ? t('frontend.filters.show_unavailable_offerings')
              : t('frontend.filters.hide_unavailable_offerings')}
          </span>
        </div>
      )}
    </>
  )
}

export default ToggleUnavailableOfferings
