import { MinusIcon, PlusIcon, UserGroupIcon, UserIcon, UsersIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next'
import { useOffering } from './Context'

export const GuestInput = ({ maxGuests }) => {
  const { numberOfGuests, setNumberOfGuests, isCouples } = useOffering()
  const { t } = useTranslation()

  if (isCouples) {
    return (
      <div className="border border-gray-300 rounded-md flex overflow-hidden text-sm flex-1 h-12 px-2 space-x-1 items-center">
        <UsersIcon className="w-5 h-5 text-accent" />
        <span className="font-medium">2</span>
        <span className="text-gray-500">
          {' '}
          {t('frontend.check_availability.guest_input_suffix', { count: 2 })}
        </span>
      </div>
    )
  }

  const handleIncrement = () => {
    if (maxGuests && numberOfGuests >= maxGuests) {
      return
    }

    setNumberOfGuests(numberOfGuests + 1)

    window.gtag('event', 'guest_input_modify', {
      event_category: 'engagement',
      event_label: 'increment',
    })
  }

  const handleDecrement = () => {
    setNumberOfGuests(numberOfGuests - 1)

    window.gtag('event', 'guest_input_modify', {
      event_category: 'engagement',
      event_label: 'decrement',
    })
  }

  return (
    <div className="border border-gray-300 rounded-md flex overflow-hidden text-sm h-12">
      <button
        onClick={handleDecrement}
        title={t('frontend.check_availability.guest_input_decrement')}
        className="px-3 bg-gray-50 border-r border-gray-300 disabled:opacity-50 disabled:cursor-not-allowed"
        disabled={numberOfGuests <= 1}
      >
        <MinusIcon className="w-5 h-5 mx-auto" />
      </button>
      <div className="flex-1">
        <div className="mx-auto items-center flex justify-center space-x-1 h-full px-2">
          {numberOfGuests === 1 && <UserIcon className="w-5 h-5 text-accent" />}

          {numberOfGuests === 2 && <UsersIcon className="w-5 h-5 text-accent" />}

          {numberOfGuests > 2 && <UserGroupIcon className="w-5 h-5 text-accent" />}
          <span className="font-medium">{numberOfGuests}</span>
          <span className="text-gray-500">
            {t('frontend.check_availability.guest_input_suffix', { count: numberOfGuests })}
          </span>
        </div>
      </div>
      <button
        onClick={handleIncrement}
        title={t('frontend.check_availability.guest_input_increment')}
        className="px-3 bg-gray-50 border-l border-gray-300 disabled:opacity-50 disabled:cursor-not-allowed"
        disabled={maxGuests && numberOfGuests >= maxGuests}
      >
        <PlusIcon className="w-5 h-5 mx-auto" />
      </button>
    </div>
  )
}
