import { useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider, useMutation } from 'react-query'
import { VouchersApi } from '@trybeapp/sdk.shop'
import { formatMoney } from '../../utilities/MoneyUtils'
import { useApplyVoucher } from './ApplyVoucher'
import { useTranslation } from 'react-i18next'

const vouchersApi = new VouchersApi()
const queryClient = new QueryClient()

export const VoucherLookup = ({ siteId }) => {
  const { t } = useTranslation()

  return (
    <QueryClientProvider client={queryClient}>
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        {t('frontend.voucher_lookup.look_up_voucher')}
      </h3>
      <LookupForm siteId={siteId} />
    </QueryClientProvider>
  )
}

const LookupForm = ({ siteId }) => {
  const { t } = useTranslation()
  const [voucherCode, setVoucherCode] = useState('')

  const {
    isLoading,
    isSuccess,
    isError,
    mutate,
    data: { data: voucher } = {},
    error,
  } = useMutation(() => vouchersApi.publicVoucherCodeLookup(voucherCode, { siteId }))

  const handleVoucherChange = (event) => {
    const value = event.target.value ?? ''
    setVoucherCode(value.toUpperCase())
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    mutate()
  }

  return (
    <>
      <form className="my-5 sm:flex sm:items-center">
        <div className="w-full sm:max-w-xs">
          <input
            type="text"
            name="email"
            id="email"
            className="shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 block w-full border-gray-300 rounded-md sm:text-2xl"
            placeholder={t('frontend.voucher_lookup.voucher_code')}
            value={voucherCode}
            onChange={handleVoucherChange}
            disabled={isLoading}
          />
        </div>
        <button
          type="submit"
          className="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-xl sm:leading-8"
          onClick={handleSubmit}
          disabled={isLoading || !voucherCode}
        >
          {t('frontend.voucher_lookup.look_up')}
        </button>
      </form>
      {isSuccess && <LookupResults voucher={voucher} />}
      {isError && <LookupError error={error} />}
    </>
  )
}

const LookupResults = ({ voucher }) => {
  const { t } = useTranslation()

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 max-w-screen-sm">
      <div className="px-4 py-5 sm:px-6 font-bold">
        {voucher.voucher_name ?? t('frontend.voucher_lookup.gift_voucher')}
      </div>
      {voucher.amount_type !== 'discount_to_zero' && (
        <div className="px-4 py-5 sm:p-6">
          {t('frontend.voucher_lookup.balance', {
            amount: formatMoney(voucher.balance / 100, voucher.currency),
          })}
        </div>
      )}
      {voucher.amount_type === 'discount_to_zero' && (
        <OfferingsList voucher={voucher} offerings={voucher.valid_offerings} />
      )}
    </div>
  )
}

const OfferingsList = ({ voucher, offerings }) => {
  const { mutate: applyVoucher, error } = useApplyVoucher()
  const [errorMessage, setErrorMessage] = useState(null)
  const { t } = useTranslation()

  const handleSelect = (offering) => {
    applyVoucher(voucher.code, {
      onSuccess: () => {
        const slug = offering.offering_name.toLowerCase().replace(/\s+/g, '-')
        window.location = `/items/${offering.offering_id}/${slug}`
      },
    })
  }

  useEffect(() => {
    const defaultMessage = t('frontend.voucher_lookup.error')

    if (error && error.body) {
      error.json().then((data) => setErrorMessage(data.message ?? defaultMessage))
    } else if (error) {
      setErrorMessage(defaultMessage)
    }
  }, [error])

  return (
    <div className="px-4 py-5 sm:p-6">
      <div className="mb-2">{t('frontend.voucher_lookup.valid_for_following_items')}</div>
      {errorMessage && <div className="mb-2 text-red-700">{errorMessage}</div>}
      {offerings.map((offering, i) => (
        <div key={i} className="flex items-center py-1">
          <div className="flex-1">{offering.display_name}</div>
          <div className="flex-0">
            <button
              className="w-full items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => handleSelect(offering)}
            >
              {t('frontend.voucher_lookup.select')}
            </button>
          </div>
        </div>
      ))}
    </div>
  )
}

const LookupError = ({ error }) => {
  let message
  const { t } = useTranslation()

  switch (error.status) {
    case 404:
      message = t('frontend.voucher_lookup.voucher_not_found')
      break

    default:
      message = error.body?.message
  }

  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <svg
            className="h-5 w-5 text-red-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{message}</h3>
        </div>
      </div>
    </div>
  )
}
