import { RadioGroup } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import { RateOption } from './RateOption'

export const RatePicker = ({ rates = [], onChange, selectedRateId }) => {
  const { t } = useTranslation()

  return (
    <div>
      <input type="hidden" name="membership_rate_id" value={selectedRateId} />
      <RadioGroup value={selectedRateId} onChange={onChange} className="block w-full">
        <RadioGroup.Label>
          <div className="text-lg font-medium">{t('frontend.self_signup.rate_picker.header')}</div>
          <p className="text-sm text-gray-500">
            {t('frontend.self_signup.rate_picker.subheading')}
          </p>
        </RadioGroup.Label>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-4 w-full">
          {rates.map((rate, index) => (
            <RateOption rate={rate} key={index} />
          ))}
        </div>
      </RadioGroup>
    </div>
  )
}
