import { useState, useEffect } from 'react';
// Our hook
export default function useDebounce(value, delay) {
    // State and setters for debounced value
    var _a = useState(value), debouncedValue = _a[0], setDebouncedValue = _a[1];
    useEffect(function () {
        // Set debouncedValue to value (passed in) after the specified delay
        var handler = setTimeout(function () {
            setDebouncedValue(value);
        }, delay);
        return function () {
            clearTimeout(handler);
        };
    }, [delay, value]);
    return debouncedValue;
}
