import { locale } from '../../app'

export const Money = ({ amount = 0, currency = 'gbp', showDecimals = true }) => {
  return <>{formatMoney({ amount, locale, currency, showDecimals })}</>
}

export const formatMoney = ({ amount = 0, currency = 'gbp', showDecimals = true }) => {
  const formattedCurrency = currency.toUpperCase()

  amount = baseAmountToCurrencyAmount(amount, formattedCurrency)

  let formatted = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: formattedCurrency,
  }).format(amount)

  if (showDecimals === false) {
    const decimalSeparator = new Intl.NumberFormat(locale).format(1.11).match(/[^1]/)[0]

    // Match on the decimal separator followed by one or more digits and white space
    const decimalsRegex = new RegExp(`\\${decimalSeparator}\\d+(\\s)?`)

    // Replace the decimal separator, decimals and the white space with just the white space
    formatted = formatted.replace(decimalsRegex, '$1')
  }

  return formatted
}

export const baseAmountToCurrencyAmount = (amount, currency) => {
  const formattedCurrency = currency.toUpperCase()

  switch (formattedCurrency) {
    default:
    case 'GBP':
      return amount / 100
  }
}

export const amountToCurrencyBaseAmount = (amount, currency) => {
  const formattedCurrency = currency.toUpperCase()

  switch (formattedCurrency) {
    default:
    case 'GBP':
      return amount * 100
  }
}
