import { useMemo, useState } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import { QueryClient, QueryClientProvider, useMutation } from 'react-query'
import { frontendBaseUrl } from './OrderHooks'
import { Modal, ModalInner } from './Modal'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

const useApplyCoupon = () =>
  useMutation(async (couponId) => {
    const req = await fetch(frontendBaseUrl + '/basket/apply-coupon', {
      method: 'post',
      headers,
      body: JSON.stringify({
        code: couponId,
      }),
      credentials: 'include',
    })

    if (req.status > 299) throw await req.json()

    return req
  })

const useRemoveCoupon = () =>
  useMutation(async (couponId) => {
    const req = await fetch(frontendBaseUrl + '/basket/remove-coupon/' + couponId, {
      method: 'post',
      headers,
      credentials: 'include',
    })

    if (req.status > 299) throw await req.json()

    return req
  })

const CouponRow = ({ coupon, isApplied, onApply, onRemove }) => {
  const [loading, setLoading] = useState(false)

  const handleApply = async () => {
    setLoading(true)

    try {
      await onApply()
    } catch (_) {
      // Already handling the error in handleApply() on the parent
    } finally {
      setLoading(false)
    }
  }

  const handleRemove = async () => {
    if (!isApplied) return

    setLoading(true)

    try {
      await onRemove()
    } catch (_) {
      // Already handling the error on handleRemove() on the parent
    } finally {
      setLoading(false)
    }
  }

  const { t } = useTranslation()

  return (
    <li className="flex space-x-2 border border-gray-300 rounded-md shadow-sm">
      <div className="flex-1 p-3 truncate">
        <div className="flex items-center space-x-1">
          <div className="font-medium truncate">{coupon.coupon_name}</div>
          {isApplied && (
            <div className="flex-shrink-0 text-xs bg-gray-100 text-gray-700 font-medium p-0.5 px-2 rounded-md">
              Applied
            </div>
          )}
        </div>
        <div className="text-gray-500 truncate">
          {coupon.coupon_description ?? t('frontend.apply_coupon.no_description')}
        </div>

        {coupon.expires_at && (
          <div className="text-gray-500 text-xs mt-1">
            {t('frontend.my_account.credits.expiry_date', {
              date: DateTime.fromISO(coupon.expires_at).toLocaleString(DateTime.DATE_MED),
            })}
          </div>
        )}

        {!coupon.expires_at && (
          <div className="text-gray-500 text-xs mt-1">
            {t('frontend.my_account.credits.no_expiry')}
          </div>
        )}
      </div>
      <div className="flex-shrink-0 border-l border-gray-300 border-dashed relative">
        <div className="absolute -top-1.5 -left-1.5 h-3 w-3 rounded-full border border-gray-300 bg-white after:absolute after:h-3 after:w-3 after:bg-white after:-left-px after:-top-1.5"></div>
        <div className="absolute -bottom-1.5 -left-1.5 h-3 w-3 rounded-full border border-gray-300 bg-white after:absolute after:h-3 after:w-3 after:bg-white after:-left-px after:-bottom-1.5"></div>
        {!isApplied && (
          <button
            className="block h-full p-2 px-3 font-medium text-accent hover:bg-accent/10 hover:text-accent/90 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-white"
            onClick={handleApply}
            title={t('frontend.apply_coupon.apply_credit')}
            disabled={loading}
          >
            {t('frontend.apply_voucher.apply')}
          </button>
        )}

        {isApplied && (
          <button
            className="block h-full p-2 px-3 font-medium text-accent hover:bg-accent/10 hover:text-accent/90 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-white"
            onClick={handleRemove}
            title={t('frontend.apply_coupon.remove_credit')}
            disabled={loading}
          >
            {t('frontend.apply_voucher.remove')}
          </button>
        )}
      </div>
    </li>
  )
}

const ApplyCouponInner = ({ coupons, applied }) => {
  const [expanded, setExpanded] = useState(false)
  const [showingError, setShowingError] = useState(null)

  const appliedCoupons = useMemo(
    () => JSON.parse(applied).map((coupon) => coupon.customer_credit_id),
    [applied]
  )

  const couponsToDisplay = useMemo(() => {
    const parsed = JSON.parse(coupons)

    if (!expanded) {
      return parsed.slice(0, 3)
    }

    return parsed
  }, [coupons, expanded])

  const { mutateAsync: apply } = useApplyCoupon()

  const { mutateAsync: remove } = useRemoveCoupon()

  const handleApply = (couponId) => {
    return apply(couponId, {
      onSuccess: () => {
        window.location.reload()
      },
      onError: (e) => {
        setShowingError(e)
      },
    })
  }

  const handleRemove = (couponId) => {
    return remove(couponId, {
      onSuccess: () => {
        window.location.reload()
      },
      onError: (e) => {
        setShowingError(e)
      },
    })
  }

  const { t } = useTranslation()

  return (
    <>
      <ul className="font-normal mt-1 space-y-3">
        {couponsToDisplay.map((coupon) => (
          <CouponRow
            key={coupon.id}
            coupon={coupon}
            isApplied={appliedCoupons.includes(coupon.id)}
            onApply={() => handleApply(coupon.id)}
            onRemove={() => handleRemove(coupon.id)}
          />
        ))}

        <button
          type="button"
          onClick={() => setExpanded(!expanded)}
          className="space-x-1 font-medium text-accent hover:text-accent/90 text-center w-full flex items-center justify-center"
        >
          {!expanded && <ChevronDownIcon className="w-5 h-5" />}
          {expanded && <ChevronUpIcon className="w-5 h-5" />}
          <span>
            {expanded ? t('frontend.apply_coupon.show_less') : t('frontend.apply_coupon.show_more')}
          </span>
        </button>
      </ul>

      <Modal open={!!showingError} onClose={() => setShowingError(null)} size="max-w-md">
        <ModalInner>
          <div className="p-6">
            <div className="font-medium text-xl">
              {t('frontend.apply_coupon.couldnt_be_applied')}
            </div>
            <div className="text-sm text-gray-500 mt-2">{showingError?.message}</div>

            <button
              className="border w-full border-gray-300 shadow-sm text-gray-800 p-3 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-accent mt-2 font-medium rounded-md"
              onClick={() => setShowingError(null)}
            >
              {t('frontend.cancel_order_item_button.close')}
            </button>
          </div>
        </ModalInner>
      </Modal>
    </>
  )
}

export const ApplyCoupon = (props) => {
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <ApplyCouponInner {...props} />
    </QueryClientProvider>
  )
}
