import { ShareIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next'

export const ShareButton = ({ offeringId }) => {
  if (!navigator.share) {
    return null
  }

  const handleClick = () => {
    navigator
      .share({
        title: document.title,
        url: window.location.href,
      })
      .then(() => {
        window.gtag('event', 'share', {
          item_id: offeringId,
        })
      })
  }

  const { t } = useTranslation()

  return (
    <button
      type="button"
      className="relative inline-flex p-3 px-4 space-x-2 items-center justify-center bg-white text-sm font-medium text-gray-600 hover:text-accent focus:z-10 focus:outline-none focus:ring-1 focus:ring-accent focus:border-accent"
      onClick={handleClick}
      title={t('frontend.share_this_page')}
    >
      <ShareIcon className="w-5 h-5" aria-hidden="true" />
      <span className="hidden md:block">{t('frontend.share')}</span>
    </button>
  )
}
