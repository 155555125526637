import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'

export const NavMenu = ({ showMemberships, showVouchers, overnightBookings }) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden z-40"
          onClose={() => setOpen(false)}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-y-0 left-0 pr-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <div className="w-screen max-w-sm">
                  <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                    <Dialog.Title className="sr-only">{t('frontend.links.menu')}</Dialog.Title>
                    <button
                      onClick={() => setOpen(false)}
                      className="inline-flex w-auto m-3 p-3 px-3 rounded-lg space-x-1 focus:outline-none focus:ring focus:ring-accent"
                    >
                      <XIcon className="w-6 h-6" />
                      <span>{t('frontend.links.close')}</span>
                    </button>
                    <nav className="m-3 text-lg font-medium">
                      <ul>
                        <a
                          href="/"
                          className="block rounded-lg p-3 focus:outline-none focus:ring focus:ring-accent"
                        >
                          {t('frontend.links.home')}
                        </a>

                        {overnightBookings === 'true' && (
                          <a
                            href="/overnight-bookings"
                            className="block rounded-lg p-3 focus:outline-none focus:ring focus:ring-accent"
                          >
                            {t('frontend.links.overnight_bookings')}
                          </a>
                        )}

                        <a
                          href="/my-account"
                          className="block md:hidden rounded-lg p-3 focus:outline-none focus:ring focus:ring-accent"
                        >
                          {t('frontend.links.my_account')}
                        </a>

                        {showMemberships === 'true' && (
                          <a
                            href="/memberships"
                            className="block rounded-lg p-3 focus:outline-none focus:ring focus:ring-accent"
                          >
                            {t('frontend.links.memberships')}
                          </a>
                        )}

                        {showVouchers === 'true' && (
                          <>
                            <a
                              href="/vouchers"
                              className="block rounded-lg p-3 focus:outline-none focus:ring focus:ring-accent"
                            >
                              {t('frontend.links.vouchers')}
                            </a>
                            <a
                              href="/voucher-lookup"
                              className="block rounded-lg p-3 focus:outline-none focus:ring focus:ring-accent"
                            >
                              {t('frontend.links.voucher_lookup')}
                            </a>
                          </>
                        )}
                      </ul>
                    </nav>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <button
        onClick={() => setOpen(true)}
        type="button"
        className="text-gray-400 p-2 -ml-2 flex rounded-md items-center"
      >
        <span className="sr-only">{t('frontend.links.open_menu')}</span>
        <MenuIcon className="w-6 h-6" />
      </button>
    </>
  )
}
