import { useTranslation } from 'react-i18next'
import { ModalLink } from '../ModalLink'
import { useMembership } from './index'

export const ProceedButton = () => {
  const { t } = useTranslation()

  const {
    isLoading,
    paymentDueToday,
    mandateRequired,
    typeName,
    typeTerms,
    siteTerms,
    sitePrivacy,
  } = useMembership()

  const buttonText = paymentDueToday
    ? `${t('frontend.become_a_member.proceed_button.proceed_to_payment')}`
    : mandateRequired
      ? `${t('frontend.become_a_member.proceed_button.set_up_payments')}`
      : `${t('frontend.become_a_member.proceed_button.continue')}`
  const continueText = t('frontend.become_a_member.proceed_button.by_clicking_proceed', {
    buttonText,
    typeName,
  })

  // Now we need to replace the text wrapped in ** with the corresponding <ModalLink component.
  // In order to work out the order of the matches, we'll get an array of the matches
  const matches = continueText.match(/\*\*(.*?)\*\*/g)

  // Iterate through the parts of the string that are wrapped and are not wrapped
  const parts = continueText.split('**')
  const continueTextWithLinks = parts.map((part, index) => {
    // If the index is even, it's not wrapped in ** so we can just return it
    if (index % 2 === 0) {
      return <span>{part}</span>
    }

    // If the index is odd, it's wrapped in ** so we need to return the corresponding <ModalLink> component
    const currentIndex = matches.indexOf(`**${part}**`)
    switch (currentIndex) {
      case 0: // The first one takes the content of `typeTerms`
        return <ModalLink text={part} contents={typeTerms} />
      case 1: // The second one takes the content of `siteTerms`
        return <ModalLink text={part} contents={siteTerms} />
      case 2: // The third one takes the content of `sitePrivacy`
        return <ModalLink text={part} contents={sitePrivacy} />
      default:
        return part
    }
  })

  return (
    <>
      <button
        type="submit"
        disabled={isLoading}
        className="w-full mt-8 py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-on-accent bg-accent hover:bg-accent-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent"
      >
        {buttonText}
      </button>
      <div className="text-gray-500 mt-4 text-sm">
        <p className="my-2">{continueTextWithLinks}</p>
        <p className="my-2">
          {/* Both a payment and a mandate are required */}
          {paymentDueToday && mandateRequired && (
            <> {t('frontend.become_a_member.proceed_button.payment_and_mandate_required')}.</>
          )}

          {/* Payment required but no mandate */}
          {paymentDueToday && !mandateRequired && (
            <>{t('frontend.become_a_member.proceed_button.payment_required')}.</>
          )}

          {/* Mandate requiree but no payment */}
          {!paymentDueToday && mandateRequired && (
            <>{t('frontend.become_a_member.proceed_button.mandate_required')}.</>
          )}
        </p>
      </div>
    </>
  )
}
