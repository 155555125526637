import 'react-phone-number-input/style.css'
import { default as BasePhoneInput } from 'react-phone-number-input'
import { useState } from 'react'

export const PhoneInput = (props) => {
  const [val, setVal] = useState(props.value)

  return (
    <BasePhoneInput
      {...props}
      value={val}
      onChange={setVal}
      defaultCountry={props.defaultCountry ?? 'GB'}
    />
  )
}
