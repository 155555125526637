export const formatMoney = (amount, currency = 'GBP', locale = 'en-GB', showDecimals = true) => {
  let formatted = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency.toUpperCase(),
  }).format(amount)

  if (showDecimals === false) {
    const decimalSeparator = new Intl.NumberFormat(locale).format(1.11).match(/[^1]/)[0]

    // Match on the decimal separator followed by one or more digits and white space
    const decimalsRegex = new RegExp(`\\${decimalSeparator}\\d+(\\s)?`)

    // Replace the decimal separator, decimals and the white space with just the white space
    formatted = formatted.replace(decimalsRegex, '$1')
  }

  return formatted
}
