import { useTranslation } from 'react-i18next'
import { useMembership } from './index'

export const Stages = () => {
  const { t } = useTranslation()
  const { isLoading, mandateRequired, paymentDueToday } = useMembership()

  return (
    <nav className="flex" aria-label="Progress">
      <ol role="list" className="space-y-6">
        <li>
          <span className="flex items-start">
            <span className="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
              <svg
                className="h-full w-full text-accent"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
            <span className="ml-3 text-sm font-medium text-gray-500">
              {t('frontend.become_a_member.stages.pick_membership')}
            </span>
          </span>
        </li>

        <li>
          <div className="flex">
            <span
              className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
              aria-hidden="true"
            >
              <span className="absolute h-4 w-4 rounded-full bg-gray-200"></span>
              <span className="relative block w-2 h-2 bg-accent rounded-full"></span>
            </span>
            <span className="ml-3 text-sm font-medium text-accent">
              {t('frontend.become_a_member.stages.choose_rate')}
            </span>
          </div>
        </li>

        {paymentDueToday && (
          <li>
            <div className="flex items-start">
              <div
                className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
                aria-hidden="true"
              >
                <div className="h-2 w-2 bg-gray-300 rounded-full"></div>
              </div>
              <p className="ml-3 text-sm font-medium text-gray-500">
                {t('frontend.become_a_member.stages.payment')}
              </p>
            </div>
          </li>
        )}

        {mandateRequired && (
          <li>
            <div className="flex items-start">
              <div
                className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center"
                aria-hidden="true"
              >
                <div className="h-2 w-2 bg-gray-300 rounded-full"></div>
              </div>
              <p className="ml-3 text-sm font-medium text-gray-500">
                {t('frontend.become_a_member.stages.set_up_payments')}
              </p>
            </div>
          </li>
        )}
      </ol>
    </nav>
  )
}
