import { BasketItem, LoadingBasketItem } from './BasketSlideover/BasketItem'
import { QueryClientProvider } from 'react-query'
import { getQueryClient, useOrder, useRemoveFromOrder } from './OrderHooks'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import { EditItem } from './EditItem'
import { useTranslation } from 'react-i18next'

const queryClient = getQueryClient()

export const SummaryList = ({
  onRemove,
  withImages = false,
  onClose,
  onEditFinished = () => {},
}) => {
  const { isLoading, isError, refetch, data: { data: basket = {} } = {} } = useOrder()

  const { mutateAsync: removeItem } = useRemoveFromOrder()

  const handleEditFinished = async () => {
    await refetch()
    onEditFinished()
  }

  const [editingItem, setEditingItem] = useState(null)

  const { t } = useTranslation()

  if (!isLoading && !isError && basket?.items.length === 0) {
    return (
      <div className="h-full flex flex-col items-center justify-center pb-12 space-y-4">
        <div className="text-2xl font-medium text-gray-500">
          {t('frontend.basket.empty.header')}
        </div>
        <button
          type="button"
          className="text-accent font-medium hover:text-accent-dark"
          onClick={() => onClose()}
        >
          {t('frontend.basket.empty.button_text')}
          <span aria-hidden="true"> &rarr;</span>
        </button>
      </div>
    )
  }

  const handleRemove = async (item) => {
    const promise = removeItem(item.id)

    if (onRemove && typeof onRemove === 'function') {
      promise.then(() => onRemove())
    }

    return promise
  }

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      height: 'auto',
      transition: {
        staggerChildren: 0.25,
      },
    },
    exit: {
      opacity: 0,
      height: 0,
    },
  }

  const displayItems = basket.items
    ? basket.items.filter((item) => item.item_configuration?.share_basket_item !== true)
    : []

  return (
    <div className="space-y-6">
      {isLoading && (
        <>
          <LoadingBasketItem />
          <LoadingBasketItem />
          <LoadingBasketItem />
        </>
      )}

      <AnimatePresence>
        {!isLoading && !isError && (
          <motion.ul variants={container} initial="hidden" animate="show" className="space-y-6">
            {displayItems.map((item) => (
              <BasketItem
                clickable
                canEdit
                key={item.id}
                item={item}
                onRemove={handleRemove}
                onEdit={() => setEditingItem(item)}
                withImages={withImages}
                reportAnalytics
              />
            ))}
          </motion.ul>
        )}
      </AnimatePresence>

      <EditItem
        item={editingItem}
        onClose={() => setEditingItem(null)}
        onEditFinished={handleEditFinished}
        siteId={basket?.site_id}
      />
    </div>
  )
}

export const BasketSummaryList = (props) => (
  <QueryClientProvider client={queryClient}>
    <SummaryList {...props} />
  </QueryClientProvider>
)
