/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'

export const Modal = ({ children, open, onClose, size = 'sm:max-w-sm' }) => (
  <Transition.Root show={open} as={Fragment}>
    <Dialog open={open} className="relative z-50" onClose={onClose} static>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog.Overlay className="fixed inset-0 bg-gray-800 bg-opacity-75 backdrop-blur transition-opacity" />
      </Transition.Child>

      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
        &#8203;
      </span>

      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div className={`fixed inset-0 ${size} mx-auto md:my-8`}>
          <Dialog.Panel className="bg-white rounded-lg h-full md:h-auto max-h-full overflow-y-auto">
            {children}
          </Dialog.Panel>
        </div>
      </Transition.Child>
    </Dialog>
  </Transition.Root>
)

export const ModalInner = ({ children }) => (
  <div className="bg-white rounded-lg relative">{children}</div>
)

export const ModalCloseButton = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute top-0 right-0 rounded-full hover:bg-gray-50 p-3 m-3 text-gray-400 hover:text-gray-800"
    title="Close"
  >
    <XIcon className="w-6 h-6" />
  </button>
)
