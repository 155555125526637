import { useState } from 'react'
import { useOffering } from '../AvailabilityCheck/Context'
import { usePackageSlots } from '../OrderHooks'
import { ChoiceOption } from './ChoiceOption'
import { DateTime } from 'luxon'
import { motion, AnimatePresence } from 'framer-motion'
import { Spinner } from '../Spinner'
import { useMemo } from 'react'
import { formatMoney } from '../Money'
import { useTranslation } from 'react-i18next'

const SelectedChoiceSummary = ({ selections }) => {
  const selectionText = selections
    .map((selection) => {
      const hideTimes = selection.item_configuration?.hide_times === true
      let str = selection.offering_name

      if (selection.time && !hideTimes) {
        str += ` at ${DateTime.fromISO(selection.time).toLocaleString(DateTime.TIME_SIMPLE)}`
      }

      return str
    })
    .join(', ')

  return <div>{selectionText}</div>
}

const maxOptionsForInitialLookup = 5

const OptionalTag = () => {
  const { t } = useTranslation()

  return (
    <span className="bg-gray-100 text-xs text-gray-500 p-1 px-2 rounded-lg ml-2 font-medium relative -top-[1px]">
      {t('frontend.check_availability.package_config.optional')}
    </span>
  )
}

const PickInstructions = ({ maxOptions = 1, budget = null, remainingBudget = 0, currency }) => {
  const { t } = useTranslation()

  const budgetTxt = useMemo(() => {
    if (budget > 0 && remainingBudget >= 0) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: t('frontend.check_availability.package_config.budget_remainder', {
              budget: formatMoney({ amount: remainingBudget, currency }),
            }).replace(/\*\*(.*?)\*\*/g, '<span class="font-medium">$1</span>'),
          }}
        />
      )
    } else if (budget > 0 && remainingBudget < 0) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: t('frontend.check_availability.package_config.budget_exceeded', {
              excess: formatMoney({ amount: Math.abs(remainingBudget), currency }),
            }).replace(/\*\*(.*?)\*\*/g, '<span class="font-medium">$1</span>'),
          }}
        />
      )
    }
  }, [budget, remainingBudget, currency])

  const optionsTxt = useMemo(() => {
    if (maxOptions === 1) {
      // They can only pick one, so no text is needed here.
      return null
    }

    if (maxOptions === null) {
      return t('frontend.check_availability.package_config.unlimited_max_options')
    }

    return t('frontend.check_availability.package_config.specific_maximum_options', {
      count: maxOptions,
    })
  }, [maxOptions])

  return (
    <div className="text-xs text-gray-500">
      {optionsTxt && <p>{optionsTxt}</p>}
      {budgetTxt && <p>{budgetTxt}</p>}
    </div>
  )
}

export const Choice = ({
  choice,
  index,
  canEdit,
  onAdd,
  onRemove,
  onContinue,
  onShowTimePicker,
  onEdit,
  expanded,
  selectedOption,
  requiresSlots,
  budget = {},
  hidePrices = false,
}) => {
  const [timePickerActiveForOption, setTimePickerActiveForOption] = useState(null)
  const [editing, setEditing] = useState(false)
  const { id, date, basketId, basketItemId, currency } = useOffering()
  const { t } = useTranslation()

  const skipInitialAvailabilityCheck = useMemo(() => {
    return choice.options.length > maxOptionsForInitialLookup
  }, [choice?.options?.length])

  const makeChoice = async (option, vals = {}, optionIndex) => {
    // null means "No thanks", or "Next" when unlimited choices.
    // No need to update basket.
    if (option === null) {
      await onContinue()

      return
    }

    await onAdd(
      {
        choice_id: choice.id,
        option_id: option.id,
        ...vals,
      },
      optionIndex
    )

    setTimePickerActiveForOption(null)

    // It's not shop-til-you-drop, so tell the parent to progress to the next stage.
    if (choice.max_options !== null) {
      onContinue()
    }
  }

  const removeChoice = async (option) => {
    for (const packageItem of selectedOption.filter((i) => i.offering_id === option.offering.id)) {
      await onRemove(packageItem.id)
    }
  }

  const handleEdit = async () => {
    setEditing(true)
    await onEdit()
    setEditing(false)
  }

  const handleRequireSlot = (optionId, optionIndex) => {
    onShowTimePicker(optionIndex)
    setTimePickerActiveForOption(optionId)
  }

  const shouldShowChangeButton = useMemo(() => {
    return !expanded && selectedOption !== undefined && canEdit
  }, [selectedOption, expanded, canEdit])

  return (
    <div data-trybe-element="package-choice">
      <div className="px-4 flex items-start relative bg-white space-x-3">
        <div className="flex-shrink-0">
          <div
            className={`
            rounded-full w-5 h-5 ring-8 ring-on-accent relative top-[3px] font-medium text-center text-xs flex items-center justify-center
            ${expanded ? 'bg-accent text-on-accent' : 'bg-gray-200 text-gray-600'}
          `}
          >
            {index}
          </div>
        </div>
        <div className="flex-1">
          <div className="">
            <div className="flex justify-between">
              <div className="flex-1">
                <h1 className="text-xl font-medium text-gray-800 flex justify-between items-start">
                  <div>
                    {choice.name}
                    {choice.optional && <OptionalTag />}
                  </div>
                </h1>
              </div>
              <div className="flex-shrink-0">
                {shouldShowChangeButton && (
                  <button
                    className="flex-shrink-0 relative -top-1 -right-1 text-sm font-medium text-accent p-2 rounded-md hover:bg-accent/10 disabled:hover:bg-white disabled:cursor-not-allowed"
                    title={t('frontend.check_availability.package_config.change_option')}
                    onClick={() => handleEdit()}
                    disabled={editing}
                  >
                    {editing ? (
                      <Spinner width="w-4" height="h-4" margin="my-1" />
                    ) : (
                      t('frontend.check_availability.package_config.change_option')
                    )}
                  </button>
                )}
              </div>
            </div>

            <div>
              <h2 className="text-gray-500">
                {expanded && choice.description}

                {!expanded && <SelectedChoiceSummary selections={selectedOption} />}
              </h2>
              {expanded && (
                <PickInstructions
                  maxOptions={choice.max_options}
                  budget={!hidePrices ? budget.budget : 0}
                  remainingBudget={budget.remaining}
                  currency={currency}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {expanded && (
          <motion.div
            style={{ overflow: 'hidden' }}
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="space-y-2 my-2">
              {choice.optional && (
                <button
                  className="p-2 px-4 flex leading-7 items-start space-x-3 w-full text-left cursor-pointer bg-white hover:bg-gray-50 rounded-lg focus:ring-accent focus:ring-2 focus:ring-offset-1"
                  onClick={() => makeChoice(null)}
                  title={t('frontend.check_availability.package_config.no_thanks')}
                  data-trybe-element="package-choice-skip-button"
                >
                  <div
                    className={`w-5 h-5 relative top-1 border border-gray-300 shadow-sm bg-white rounded-md`}
                  />
                  <div>{t('frontend.check_availability.package_config.no_thanks')}</div>
                </button>
              )}

              {choice.options.map((option, index) => (
                <ChoiceOption
                  shouldSkipInitialAvailabilityCheck={skipInitialAvailabilityCheck}
                  applied={selectedOption.some((i) => i.offering_id === option.offering.id)}
                  option={option}
                  key={index}
                  onAdd={(vals) => makeChoice(option, { ...vals }, index)}
                  onRemove={() => removeChoice(option)}
                  onHideTimePicker={() => setTimePickerActiveForOption(null)}
                  requiresSlots={requiresSlots}
                  onRequireSlot={() => handleRequireSlot(option.id, index)}
                  getSlotsHook={() =>
                    usePackageSlots(id, choice.id, date, basketId, basketItemId, option.id, {
                      enabled: !skipInitialAvailabilityCheck && requiresSlots,
                      refetchOnWindowFocus: false,
                    })
                  }
                  showFromPrice={!hidePrices && budget.budget > 0}
                  shouldSelectOnMount={choice.options.length === 1 && !choice.optional}
                  shouldShowTimePicker={timePickerActiveForOption === option.id}
                  shouldShowPriceChange={!hidePrices && option.price_change > 0 && !budget?.budget}
                />
              ))}
            </div>

            {(choice.max_options === null || choice.max_options > 1) && (
              <div className="mx-4">
                <button
                  className={`disabled:cursor-not-allowed disabled:opacity-75 disabled:hover:bg-white mt-2 border border-gray-300 block text-center shadow-sm hover:bg-gray-100 font-medium text-gray-800 rounded-lg w-full p-2 px-4 cursor-pointer focus:ring-accent focus:ring-2 focus:ring-offset-1`}
                  onClick={() => makeChoice(null)}
                  disabled={
                    selectedOption.length < choice.min_options ||
                    (choice.max_options !== null && selectedOption.length > choice.max_options)
                  }
                  title={t('frontend.check_availability.package_config.next')}
                  data-trybe-element="package-choice-continue-button"
                >
                  {t('frontend.check_availability.package_config.next')}
                </button>
                {selectedOption.length < choice.min_options && (
                  <p className="text-xs text-gray-500 mt-1 text-center">
                    {t(
                      'frontend.check_availability.package_config.select_at_least_before_continuing',
                      {
                        count: choice.min_options,
                      }
                    )}
                  </p>
                )}
                {choice.max_options !== null && selectedOption.length > choice.max_options && (
                  <p className="text-xs text-gray-500 mt-1 text-center">
                    {t(
                      'frontend.check_availability.package_config.select_up_to_before_continuing',
                      {
                        count: choice.max_options,
                      }
                    )}
                  </p>
                )}
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
