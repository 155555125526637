import { QueryClient, QueryClientProvider } from 'react-query'
import { useOrder } from './OrderHooks'
import { Spinner } from './Spinner'

const queryClient = new QueryClient()

const CheckoutCompleteCheckInner = () => {
  useOrder({
    refetchInterval: 1000,
    onSuccess: ({ data: basket }) => {
      if (
        ['submitted', 'settled'].includes(basket.status) ||
        (basket.submit_errors && basket.submit_errors.length > 0)
      ) {
        window.location.reload()
      }
    },
  })

  return <Spinner />
}

export const CheckoutCompleteCheck = () => (
  <QueryClientProvider client={queryClient}>
    <CheckoutCompleteCheckInner />
  </QueryClientProvider>
)
