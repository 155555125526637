import { QueryClient, QueryClientProvider } from 'react-query'
import { useTotalsForMembershipRate } from '../OrderHooks'
import { ProceedButton } from './ProceedButton'
import { Stages } from './Stages'
import { Summary } from './Summary'
import { createContext, useContext, useMemo, useState } from 'react'
import { RatePicker } from './RatePicker'

const queryClient = new QueryClient()
const MembershipContext = createContext()
export const useMembership = () => useContext(MembershipContext)

const BecomeAMemberInner = ({
  rates,
  typeName,
  typeTerms,
  siteTerms,
  sitePrivacy,
  selectedRateId: initialSelectedRateId,
}) => {
  const [selectedRateId, setSelectedRateId] = useState(initialSelectedRateId)
  const parsedRates = useMemo(() => {
    try {
      return JSON.parse(rates)
    } catch (e) {
      return []
    }
  }, [rates])

  const selectedRate = useMemo(() => {
    if (selectedRateId == null) return null
    return parsedRates.find(({ id }) => id === selectedRateId)
  }, [parsedRates, selectedRateId])

  const { isLoading, data: { data: totals = {} } = {} } = useTotalsForMembershipRate(selectedRateId)

  const value = {
    selectedRateId,
    selectedRate,
    isLoading,
    totals,
    paymentDueToday: !isLoading && totals.total_price.amount > 0,
    mandateRequired: !isLoading && totals.breakdowns.monthly_fee.amount > 0,
    typeName,
    typeTerms,
    siteTerms,
    sitePrivacy,
  }

  return (
    <MembershipContext.Provider value={value}>
      <div className="flex-1 space-y-4 bg-gray-50 p-6 rounded-md">
        <RatePicker
          rates={parsedRates}
          selectedRateId={selectedRateId}
          onChange={setSelectedRateId}
        />

        <Summary />
        <ProceedButton typeName={typeName} typeTerms={typeTerms} />
      </div>
      <div className="flex-1 md:px-8 md:max-w-[400px] pt-5">
        <Stages />
      </div>
    </MembershipContext.Provider>
  )
}

export const BecomeAMember = (props) => (
  <QueryClientProvider client={queryClient}>
    <BecomeAMemberInner {...props} />
  </QueryClientProvider>
)
