import { XIcon } from '@heroicons/react/solid'
import { useMemo, useState } from 'react'
import { QueryClientProvider, QueryClient } from 'react-query'
import { Modal, ModalInner } from './Modal'
import { useCancelItem } from './OrderHooks'
import { Spinner } from './Spinner'
import { useTranslation } from 'react-i18next'

const CancelOrderItem = ({ can, message, cancelled, orderId, itemId, token }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const parsedCan = useMemo(() => JSON.parse(can), [can])
  const parsedCancelled = useMemo(() => JSON.parse(cancelled), [cancelled])
  const { t } = useTranslation()

  const { isLoading, isError, mutate } = useCancelItem(orderId, itemId, token)

  if (parsedCancelled === true) {
    return (
      <div className="text-sm text-gray-700">
        {t('frontend.cancel_order_item_button.cancelled')}
      </div>
    )
  }

  if (parsedCan === null) {
    return null
  }

  const handleClose = () => {
    if (!isLoading) {
      setModalOpen(false)
    }
  }

  const handleCancel = () => {
    mutate(null, {
      onSuccess: () => {
        setModalOpen(false)
        window.location.reload()
      },
    })
  }

  return (
    <>
      <button
        className="flex items-center space-x-1 text-sm text-gray-600 border border-gray-300 rounded-md shadow-sm px-4 py-2 font-medium hover:bg-gray-50"
        onClick={() => setModalOpen(true)}
      >
        <XIcon className="-ml-1 h-5 w-5" aria-hidden="true" />
        <span>{t('frontend.cancel_order_item_button.cancel')}</span>
      </button>
      <Modal open={modalOpen} onClose={handleClose}>
        <ModalInner>
          <div className="p-6">
            {isError && (
              <div className="text-red-500 font-medium">
                {t('frontend.cancel_order_item_button.error')}
              </div>
            )}

            {!isError && (
              <>
                <h1 className="text-lg font-medium text-gray-800">
                  {t('frontend.cancel_order_item_button.cancel_item')}
                </h1>
                {parsedCan !== true && (
                  <div className="space-y-4">
                    <p className="mt-4 text-gray-500">{message}</p>

                    <button
                      onClick={handleClose}
                      disabled={isLoading}
                      className="w-full border border-gray-300 shadow-sm p-3 font-medium rounded-md focus:outline-none focus:ring focus:ring-accent"
                    >
                      {t('frontend.cancel_order_item_button.close')}
                    </button>
                  </div>
                )}

                {parsedCan === true && (
                  <div className="space-y-4">
                    <p className="mt-4 text-gray-500">
                      {t('frontend.cancel_order_item_button.are_you_sure')}
                    </p>

                    <div className="space-y-2">
                      <button
                        onClick={handleCancel}
                        disabled={isLoading}
                        className="w-full bg-accent text-on-accent shadow-sm p-3 font-medium rounded-md focus:outline-none focus:ring focus:ring-accent focus:ring-offset-1"
                      >
                        {!isLoading && (
                          <span>{t('frontend.cancel_order_item_button.yes_cancel')}</span>
                        )}

                        {isLoading && <Spinner margin="" />}
                      </button>
                      <button
                        onClick={handleClose}
                        disabled={isLoading}
                        className="w-full border border-gray-300 shadow-sm p-3 font-medium rounded-md focus:outline-none focus:ring focus:ring-accent"
                      >
                        {t('frontend.cancel_order_item_button.no_go_back')}
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </ModalInner>
      </Modal>
    </>
  )
}

const queryClient = new QueryClient()

export const CancelOrderItemButton = (props) => (
  <QueryClientProvider client={queryClient}>
    <CancelOrderItem {...props} />
  </QueryClientProvider>
)
