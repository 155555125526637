import { toUpper } from 'lodash'
import { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { QueryClientProvider } from 'react-query'
import { getQueryClient, useOrder } from './OrderHooks'
import { useApplyPromoCode, useRemovePromoCode } from './PromoCodeHooks'

const queryClient = getQueryClient()

export const ApplyPromoCode = ({ siteId }) => (
  <QueryClientProvider client={queryClient}>
    <ApplyPromoCodeForm siteId={siteId} />
  </QueryClientProvider>
)

const ApplyPromoCodeForm = () => {
  const { data: { data: basket = {} } = {}, isLoading: isLoadingBasket } = useOrder()
  const [promoCodeInputVisible, setPromoCodeInputVisible] = useState(false)

  const { mutate: applyPromoCodeMutation, isLoading: isApplyingPromoCode } = useApplyPromoCode()
  const { mutate: removePromoCodeMutation } = useRemovePromoCode()
  const [displayError, setDisplayError] = useState(null)
  const { t } = useTranslation()

  const appliedPromoCode = basket?.applied_promo_code_id
  const isPromoCodeDiscountApplied = basket?.is_promo_code_discount_applied

  const handleApply = (promoCode) => {
    setDisplayError(null)

    if (!promoCode) {
      return
    }

    applyPromoCodeMutation(promoCode, {
      onSuccess: () => {
        window.location.reload()
        setPromoCodeInputVisible(false)
      },
      onError: (e) => {
        e.json().then((body) => setDisplayError(body?.message))
      },
    })
  }

  const handleRemove = (promoCodeId) => {
    removePromoCodeMutation(promoCodeId, {
      onSuccess: () => {
        window.location.reload()
      },
    })
  }

  if (isLoadingBasket) {
    return <div className="bg-gray-200 animate-pulse h-8 w-full" />
  }

  return (
    <>
      {appliedPromoCode && (
        <div>
          <dt className="flex space-x-3 mb-4">
            <span>
              {t('frontend.apply_promo_code.applied_promo_code', {
                code: basket.applied_promo_code.code,
                amount: basket.applied_promo_code.percentage,
              }).replace(/\*\*(.*?)\*\*/g, '<span class="font-medium">$1</span>')}
            </span>
            <button
              className="text-xs font-medium text-gray-700"
              onClick={() => handleRemove(appliedPromoCode)}
              title={t('frontend.apply_promo_code.remove_promo_code')}
            >
              {t('frontend.apply_promo_code.remove')}
            </button>
          </dt>
          {!isPromoCodeDiscountApplied && (
            <span className="text-sm text-red-700">
              {t('frontend.apply_promo_code.items_not_valid')}
            </span>
          )}
        </div>
      )}
      {!appliedPromoCode && !promoCodeInputVisible && (
        <>
          <dt className="w-full">
            <button
              className="text-sm block w-full font-medium text-gray-700 hover:bg-gray-50 border border-gray-300 rounded-md shadow-sm p-2"
              title={t('frontend.apply_promo_code.apply_a_promo_code')}
              onClick={() => setPromoCodeInputVisible(true)}
            >
              {t('frontend.apply_promo_code.apply_a_promo_code')}
            </button>
          </dt>
        </>
      )}
      {promoCodeInputVisible && (
        <>
          <PromoCodeInput
            error={displayError}
            onApply={handleApply}
            applying={isApplyingPromoCode}
          />
        </>
      )}
    </>
  )
}

const PromoCodeInput = ({ error, onApply, applying }) => {
  const [promoCode, setPromoCode] = useState('')
  const [inputRef, setInputRef] = useState(null)
  const { t } = useTranslation()

  useLayoutEffect(() => {
    if (inputRef) {
      inputRef.focus()
    }
  }, [inputRef])

  const handleSubmit = (e) => {
    e.preventDefault()
    onApply(promoCode)
  }

  return (
    <div className="flex flex-col space-y-2 w-full">
      {error && <div className="text-sm text-red-700">{error}</div>}
      <form onSubmit={handleSubmit} className="w-full flex -space-x-px items-center">
        <input
          type="text"
          name="promo_code"
          value={promoCode}
          ref={setInputRef}
          id="promoCode"
          autoComplete="false"
          placeholder={t('frontend.apply_promo_code.promo_code')}
          className="focus:ring-accent focus:border-accent relative block w-full rounded-l-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
          onChange={(event) => {
            setPromoCode(toUpper(event.target.value))
          }}
        />
        <button
          type="submit"
          className="focus:outline-none focus:ring-1 focus:ring-accent focus:border-accent whitespace-nowrap flex justify-center px-4 rounded-r-md shadow-none sm:text-sm font-medium text-gray-700 hover:bg-gray-50 border border-gray-300 h-full flex-col z-10 disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={() => onApply(promoCode)}
          disabled={applying}
        >
          <span>{t('frontend.apply_promo_code.apply')}</span>
        </button>
      </form>
    </div>
  )
}
