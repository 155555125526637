import { Fragment, useEffect, useMemo, useState } from 'react'
import { Listbox } from '@headlessui/react'
import { DateTime } from 'luxon'
import { Spinner } from '../Spinner'
import { formatMoney } from '../Money'
import { CheckIcon, ClockIcon, ExclamationIcon, SelectorIcon } from '@heroicons/react/solid'
import { useOffering } from './Context'
import { useTranslation } from 'react-i18next'

const TimeSlotButton = ({ slot, onSelect, disabled, showPrice = false, showCapacity = false }) => {
  const [isLoading, setLoading] = useState(false)
  const { guests, isCouples } = useOffering()
  const { t } = useTranslation()

  const handleClick = async () => {
    setLoading(true)

    try {
      await onSelect(slot)
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false)
    }
  }

  const startTime = useMemo(() => {
    return DateTime.fromJSDate(slot.start_time)
  }, [slot.start_time])

  useEffect(() => {
    return () => setLoading(false)
  }, [])

  const hasNoSlots = useMemo(() => {
    return showCapacity
      ? slot.remaining_capacity === 0 && !slot.waitlist_enabled
      : slot.quantity_available === 0
  }, [showCapacity, slot.remaining_capacity, slot.quantity_available, slot.waitlist_enabled])

  return (
    <button
      className="flex-shrink-0 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-white disabled:line-through overflow-hidden bg-white border relative border-gray-300 shadow-sm hover:bg-gray-50 text-gray-700 p-3 px-2 md:px-3 rounded-md font-medium focus:outline-none focus:ring focus:ring-accent"
      onClick={handleClick}
      disabled={disabled || isLoading || hasNoSlots}
    >
      {isLoading && (
        <div className="absolute bg-white inset-0 flex items-center justify-center">
          <Spinner />
        </div>
      )}
      <div>{startTime.toLocaleString(DateTime.TIME_SIMPLE).toLowerCase()}</div>

      {showCapacity && slot.remaining_capacity !== undefined && !isCouples && (
        <div className="text-xs text-gray-500 font-normal flex items-center justify-center space-x-0.5">
          {slot.remaining_capacity > 0 && slot.remaining_capacity < guests.length ? (
            <>
              <ExclamationIcon className="w-4 h-4" />
              <span>
                {t('frontend.check_availability.package_config.only_left', {
                  slots: slot.remaining_capacity,
                })}
              </span>
            </>
          ) : slot.remaining_capacity === 0 && slot.waitlist_enabled ? (
            <span>{t('frontend.basket_slideover.basket_item.waitlist')}</span>
          ) : (
            <span>
              {t('frontend.check_availability.package_config.slots_left', {
                count: slot.remaining_capacity,
                slots: slot.remaining_capacity,
              })}
            </span>
          )}
        </div>
      )}

      {showPrice && (
        <div className="text-sm text-gray-500">
          {formatMoney({ amount: slot.price, currency: slot.currency })}
        </div>
      )}

      {!showCapacity &&
        slot.quantity_available > 0 &&
        slot.quantity_available < guests.length &&
        !isCouples && (
          <div className="text-xs text-gray-500 font-normal flex items-center justify-center space-x-0.5">
            <ExclamationIcon className="w-4 h-4" />
            <span className="block">
              {t('frontend.availability_check.time_slot_picker.count_of_slots_left_long', {
                remainingSlots: slot.quantity_available,
              })}
            </span>
          </div>
        )}
    </button>
  )
}

export const DurationFilters = ({ durations, onDurationSelect, selectedDuration }) => {
  const { t } = useTranslation()

  return (
    <Listbox as="div" value={selectedDuration} onChange={onDurationSelect} className="relative">
      <Listbox.Button className="flex items-center space-x-1 rounded-md border border-gray-300 px-3 py-2 pr-1 text-gray-500 bg-white">
        <ClockIcon className="w-4 h-4 text-accent" />
        <span>
          <span
            className="inline sm:hidden"
            dangerouslySetInnerHTML={{
              __html: t('frontend.availability_check.time_slot_picker.selected_duration_short', {
                duration: selectedDuration,
              }).replace(/\*\*(.*?)\*\*/g, '<span class="font-medium text-gray-700">$1</span>'),
            }}
          />
          <span
            className="hidden sm:inline"
            dangerouslySetInnerHTML={{
              __html: t('frontend.availability_check.time_slot_picker.selected_duration_long', {
                duration: selectedDuration,
              }).replace(/\*\*(.*?)\*\*/g, '<span class="font-medium text-gray-700">$1</span>'),
            }}
          />
        </span>
        <SelectorIcon className="w-5 h-5 text-gray-500" />
      </Listbox.Button>
      <Listbox.Options className="absolute z-10 mt-1 w-52 right-0 max-h-60 rounded-md p-1 bg-white shadow-lg border border-gray-300 focus:outline-none">
        {durations.map((duration) => (
          <Listbox.Option as={Fragment} key={duration} value={duration}>
            {({ selected, active }) => (
              <li
                className={`${
                  selected || active ? 'bg-accent text-on-accent' : 'hover:bg-gray-50 text-gray-500'
                } cursor-pointer flex items-center space-x-1 px-3 py-2 rounded`}
              >
                <span className="flex-1">
                  <span
                    className={`font-medium ${
                      selected || active ? 'text-on-accent' : 'text-gray-700'
                    }`}
                  >
                    {duration}
                  </span>{' '}
                  minutes
                </span>
                {selected && <CheckIcon className="w-5 h-5" />}
              </li>
            )}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
  )
}

export const TimeSlotPicker = ({
  onSelect,
  slots,
  disabled = false,
  showPrice = false,
  showCapacity = false,
  inline = false,
  isLoading = false,
}) => {
  const { t } = useTranslation()

  return (
    <div className="">
      <div
        className={`${inline ? 'flex overflow-x-scroll space-x-2' : 'grid grid-cols-3 gap-3'}`}
        data-testid={isLoading ? 'loading-timeslots' : 'timeslots'}
      >
        {isLoading && (
          <>
            <div className="bg-gray-200 rounded-md p-8" />
            <div className="bg-gray-200 rounded-md p-8" />
            <div className="bg-gray-200 rounded-md p-8" />
            <div className="bg-gray-200 rounded-md p-8" />
            <div className="bg-gray-200 rounded-md p-8" />
            <div className="bg-gray-200 rounded-md p-8" />
            <div className="bg-gray-200 rounded-md p-8" />
            <div className="bg-gray-200 rounded-md p-8" />
            <div className="bg-gray-200 rounded-md p-8" />
            <div className="bg-gray-200 rounded-md p-8" />
            <div className="bg-gray-200 rounded-md p-8" />
            <div className="bg-gray-200 rounded-md p-8" />
          </>
        )}

        {!isLoading &&
          slots.map((slot) => (
            <TimeSlotButton
              slot={slot}
              key={`slot_${slot.start_time}_${slot.duration}`}
              onSelect={onSelect}
              disabled={disabled}
              showPrice={showPrice}
              showCapacity={showCapacity}
            />
          ))}

        {!isLoading && slots.length === 0 && (
          <div className="col-span-3 text-center text-gray-500 p-6 text-sm bg-gray-50 rounded-md">
            {t('frontend.check_availability.package_config.no_slots_available_for_date')}
          </div>
        )}
      </div>
    </div>
  )
}
