import { DateTime } from 'luxon'
import { XIcon, CalendarIcon } from '@heroicons/react/solid'
import { useOffering } from './Context'
import { useState } from 'react'
import { Spinner } from '../Spinner'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export const OfferingSummary = ({ canClose = true, onClose }) => {
  const { offeringName, date, offeringImage } = useOffering()
  const [closing, setClosing] = useState(false)
  const { t } = useTranslation()

  const handleClose = () => {
    if (!canClose) return

    setClosing(true)
    onClose()
  }

  useEffect(() => {
    return () => setClosing(false)
  }, [])

  return (
    <div className="relative">
      <div className="aspect-offering" data-trybe-element="offering-summary-image">
        {offeringImage && (
          <img
            className="aspect-offering object-cover object-center w-full h-full"
            src={offeringImage}
            alt={offeringName}
          />
        )}
      </div>
      <div className="absolute inset-0 bg-black opacity-50" />
      {canClose && (
        <div className="absolute top-0 right-0 p-3">
          <button
            className="focus:outline-none p-3 bg-white bg-opacity-10 text-white text-opacity-50 rounded-full hover:bg-opacity-20 hover:text-opacity-100"
            onClick={handleClose}
            data-trybe-element="offering-summary-close-button"
          >
            {closing && <Spinner margin={0} width="w-6" height="h-6" />}

            {!closing && (
              <>
                <span className="sr-only">
                  {t('frontend.availability_check.offering_summary.close')}
                </span>
                <XIcon className="h-6 w-6 " aria-hidden="true" />
              </>
            )}
          </button>
        </div>
      )}
      <div
        className="absolute inset-x-0 bottom-0 p-6 px-8"
        data-trybe-element="offering-summary-details"
      >
        <div
          className="font-bold text-2xl text-white tracking-tight"
          data-trybe-element="offering-summary-name"
        >
          {offeringName}
        </div>

        {date && (
          <div
            className="text-gray-300 items-center flex space-x-1"
            data-trybe-element="offering-summary-date"
          >
            <CalendarIcon className="w-4 h-4" />
            <span>{date && date.toLocaleString(DateTime.DATE_MED)}</span>
          </div>
        )}
      </div>
    </div>
  )
}
