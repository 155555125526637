import { ExclamationIcon, PlusIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { OfferingModal } from '../OfferingModal'
import { OfferingProvider, useOffering } from './Context'
import { GuestConfig } from './GuestConfig'
import { useAddToOrder } from '../OrderHooks'
import { QueryClientProvider, QueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useSiteName } from '../../useSiteName'

const AddRetailProductModal = ({ open, onClose }) => {
  const { id, price } = useOffering()
  const { t } = useTranslation()

  const { isError, error, mutateAsync: addToOrder } = useAddToOrder(id, 'product')

  const handleContinue = async (guests) => {
    try {
      const basket = await addToOrder({
        guests: guests,
      })

      window.gtag('event', 'add_to_cart', {
        currency: basket.currency.toUpperCase(),
        value: parseFloat(((price * guests.length) / 100).toFixed(2)),
        items: [
          {
            affiliation: useSiteName(),
            item_id: id,
            item_name: basket.items.find((item) => item.id === id)?.offering_name,
            quantity: guests.length,
            price: parseFloat((price / 100).toFixed(2)),
          },
        ],
      })

      onClose()

      // For now, this component is only used on the checkout page.
      // So refresh the page to update all the totals.
      window.location.reload()
    } catch (_) {
      // If an exception is thrown, the react query hook will
      // handle outputting the error for us, so no action here.
    }
  }

  return (
    <OfferingModal isOpen={open} onClose={onClose}>
      {isError && (
        <div className="mx-6 my-4 p-3 mb-0 rounded-md bg-red-100 text-red-700 flex space-x-1">
          <ExclamationIcon className="relative top-1 w-5 h-5" />
          <span>
            {error?.message ??
              t('frontend.availability_check.add_retail_product_button.an_error_occurred')}
          </span>
        </div>
      )}

      <GuestConfig
        onComplete={handleContinue}
        disallowAmendGuests
        ctaButtonText={t('frontend.availability_check.add_retail_product_button.add_to_order')}
      />
    </OfferingModal>
  )
}

export const AddRetailProductButton = ({ offeringName, offeringImage, id, guests }) => {
  const [open, setOpen] = useState(false)
  const queryClient = new QueryClient()
  const { t } = useTranslation()

  return (
    <>
      <button
        className="w-full flex items-center justify-center mt-2 text-sm text-accent border border-accent font-medium p-2 rounded-md space-x-1 hover:bg-accent/10"
        onClick={() => setOpen(true)}
        title={t('frontend.availability_check.add_retail_product_button.add_to_order')}
      >
        <PlusIcon className="w-5 h-5" />
        <span>{t('frontend.availability_check.add_retail_product_button.add_to_order')}</span>
      </button>

      <OfferingProvider
        id={id}
        offeringName={offeringName}
        offeringImage={offeringImage}
        basketGuests={guests ? JSON.parse(guests) : []}
      >
        <QueryClientProvider client={queryClient}>
          <AddRetailProductModal open={open} onClose={() => setOpen(false)} />
        </QueryClientProvider>
      </OfferingProvider>
    </>
  )
}
